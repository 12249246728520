export const populationDynamicOptions: any = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    x: {
      grid: {
        display: false,
        drawTicks: false,
      },
      position: 'top',
      ticks: {
        color: '#202224',
        align: 'center',
        baseLine: 'middle',
        font: {
          size: 14,
          weight: 400,
        },
      },
      offset: true,
    },
    y: {
      grid: {
        color: '#c6cacc',
        beginAtZero: false,
        drawTicks: false,
      },
      ticks: {
        display: false,
        maxTicksLimit: 4,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: (context: any) => {
        const value = context.dataset.data[context.dataIndex];
        const barHeight = context.chart.scales.y.getPixelForValue(0) - context.chart.scales.y.getPixelForValue(value);
        return barHeight >= 20; // Show datalabel only if the height of the portion is enough
      },
      color: '#202224',
      font: {
        size: 14,
        weight: 400,
      },
      textAlign: 'center',
      clamp: true,
      anchor: 'start',
      align: (context: any) => {
        const index = context.dataIndex;
        const datasets = context.chart.data.datasets[0].data;
        const average = datasets.reduce((acc: number, item: number) => acc + item, 0) / datasets.length;
        return datasets[index] <= average ? 'end' : 'start';
      },
    }
  },
  layout: {
    backgroundColor: '#F8F7EF',
  },
}

export const populationByAgeOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawTicks: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true,
        display: false,
      },
      grid: {
        drawTicks: false,
        color: 'rgba(0, 0, 0, 0.05)',
      },
      max: (context: any) => {
        const datasets: any = context.chart.data.datasets;
        const maxSum: any = Math.max(...context.chart.data.labels.map((_: any, index: any) =>
          datasets.reduce((sum: any, dataset: any) => sum + (+dataset.data[index] || 0), 0)
        ));

        return maxSum * 1.275;
      },
    },
  },
  plugins: {
    datalabels: {
      align: 'start',
      anchor: 'end',
      font: {
        size: 12,
        weight: 'bold',
      },
      color: '#fff',
      display: (context: any) => {
        const value = context.dataset.data[context.dataIndex];
        const barHeight = context.chart.scales.y.getPixelForValue(0) - context.chart.scales.y.getPixelForValue(value);
        return barHeight >= 20; // Show datalabel only if the height of the portion is enough
      },
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const label = context.dataset.label || '';
          return `${label}: ${context.raw}`;
        },
      },
    },
    legend: {
      display: false,
    },
  },
}

export const populationBySocialStatusOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      beginAtZero: true,
      ticks: {
        display: false,
      }
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        filter: (legendItem: any) => ['I группа', 'II группа', 'III группа', 'I топ', 'II топ', 'III топ'].includes(legendItem.text),
        usePointStyle: true,
        pointStyle: 'circle',
        boxHeight: 5
      },
    },
    datalabels: {
      display: (context: any) => {
        const value = context.dataset.data[context.dataIndex];
        const barHeight = context.chart.scales.y.getPixelForValue(0) - context.chart.scales.y.getPixelForValue(value);
        return barHeight >= 20 && context.dataset.data[context.dataIndex] !== null && context.dataset.data[context.dataIndex] !== 0;
      },
      align: (context: any) => {
        return context.dataset.label === 'I группа' || context.dataset.label === 'II группа' || context.dataset.label === 'III группа' || context.dataset.label === 'I топ' || context.dataset.label === 'II топ' || context.dataset.label === 'III топ'
          ? 'center'
          : 'start';
      },
      anchor: (context: any) => {
        return context.dataset.label === 'I группа' || context.dataset.label === 'II группа' || context.dataset.label === 'III группа' || context.dataset.label === 'I топ' || context.dataset.label === 'II топ' || context.dataset.label === 'III топ'
          ? 'center'
          : 'end';
      },
      font: {
        size: 11,
        weight: 'bold',
      },
      color: '#fff'
    },
  },
}

export const employmentOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      }
    },
    y: {
      stacked: true,
      beginAtZero: true,
      ticks: {
        display: false,
      }
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxHeight: 5
      },
    },
    datalabels: {
      display: (context: any) => {
        const value = context.dataset.data[context.dataIndex];
        const barHeight = context.chart.scales.y.getPixelForValue(0) - context.chart.scales.y.getPixelForValue(value);
        return barHeight >= 20 && context.dataset.data[context.dataIndex] !== null && context.dataset.data[context.dataIndex] !== 0;
      },
      align: 'center',
      anchor: 'center',
      font: {
        size: 11,
        weight: 'bold',
      },
      color: '#fff'
    },
  }
}

export const mcbSubjectsOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  scales: {
    x: {
      beginAtZero: true,
      stacked: true,
      ticks: {
        display: false,
        maxTicksLimit: 6,
        align: 'start'
      },
      grid: {
        drawTicks: false,
      }
    },
    y: {
      stacked: true,
      ticks: {
        color: '#202224',
        crossAlign: 'far',
        font: {
          size: 12,
          weight: 400,
        },
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        filter: (legendItem: any) => !['Всего', 'Барлығы'].includes(legendItem.text),
        usePointStyle: true,
        pointStyle: 'circle',
        boxHeight: 5,
      },
    },
    tooltip: {
      yAlign: 'bottom',
    },
    datalabels: {
      display: (context: any) => {
        return context.datasetIndex === 0 || context.datasetIndex === 4;
      },
      formatter: (value: any, context: any) => {
        if (context.datasetIndex === 0) {
          return context.chart.data.datasets[0].data[0];
        } else if (context.datasetIndex === 4) {
          const totalValue = context.chart.data.datasets[1].data[1] +
            context.chart.data.datasets[2].data[1] +
            context.chart.data.datasets[3].data[1] +
            context.chart.data.datasets[4].data[1]
          return totalValue;
        }
        return '';
      },
      anchor: 'end',
      align: 'start',
      font: {
        size: 11,
        weight: 'bold',
      },
      color: '#fff',
      clip: true,
    },
  },
}

export const registeredSubjectsOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawTicks: false,
      },
      ticks: {
        font: {
          size: 10,
        }
      }
    },
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true,
        display: false,
      },
      grid: {
        drawTicks: false,
        color: 'rgba(0, 0, 0, 0.05)',
      },
      max: (context: any) => {
        const datasets: any = context.chart.data.datasets;
        const maxSum: any = Math.max(...context.chart.data.labels.map((_: any, index: any) =>
          datasets.reduce((sum: any, dataset: any) => sum + (dataset.data[index] || 0), 0)
        ));

        return maxSum * 1.275;
      },
    },
  },
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      font: {
        size: 14,
        weight: 'bold',
      },
      color: '#000'
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const label = context.dataset.label || '';
          return `${label}: ${context.raw}`;
        },
      },
    },
    legend: {
      display: false,
    },
  },
}

// FARMING TAB

const legend = {
  display: true,
  position: 'bottom',
  labels: {
    usePointStyle: true,
    pointStyle: 'circle',
    fill: true,
    boxHeight: 4,
    font: {
      size: 12,
    },
  },
}

export const animalHusbandryOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawTicks: false,
      },
      border: {
        display: false
      },
    },
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true,
        display: false,
        maxTicksLimit: 6,
      },
      grid: {
        drawTicks: false,
        color: 'rgba(0, 0, 0, 0.05)',
      },
      border: {
        display: false
      },
      max: (context: any) => {
        const datasets: any = context.chart.data.datasets;
        const maxSum: any = Math.max(...context.chart.data.labels.map((_: any, index: any) =>
          datasets.reduce((sum: any, dataset: any) => sum + +(dataset.data[index] || 0), 0)
        ));

        return maxSum * 1.275;
      },

    },
  },
  plugins: {
    datalabels: {
      display: (context: any) => {
        const datasetIndex = context.datasetIndex;
        const value = context.dataset.data[context.dataIndex];
        const barHeight = context.chart.scales.y.getPixelForValue(0) - context.chart.scales.y.getPixelForValue(value);
        return datasetIndex === 2 ? true : barHeight >= 15;
      },
      align: (context: any) => {
        const datasetIndex = context.datasetIndex;
        return datasetIndex === 2 ? 'end' : 'center';
      },
      anchor: 'center',
      formatter: (value: any, context: any) => {
        const datasetIndex = context.datasetIndex;
        const dataIndex = context.dataIndex;
        if (datasetIndex === 2) {
          const sum = context.chart.data.datasets.reduce((acc: any, dataset: any) => acc + +dataset.data[dataIndex], 0);
          return Number.isInteger(sum) ? sum : sum;
        }
        return value;
      },
      font: {
        size: 12,
        weight: 'bold',
      },
      color: (context: any) => {
        const datasetIndex = context.datasetIndex;
        return datasetIndex === 2 ? '#009187' : '#fff';
      },

    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const label = context.dataset.label || '';
          return `${label}: ${context.raw}`;
        },
      },
    },
    legend,
  },
}

export const agriculturalGrowthOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend,
    datalabels: {
      display: 'auto',
      formatter: function (value: any) {
        return value;
      },
      font: {
        size: 10,
      },
      textAlign: 'center',
      clamp: true,
      anchor: 'center',
      align: 'bottom',
    }
  },
  scales: {
    x: {
      grid: {
        display: false,
        beginAtZero: false,
      },
      position: 'top',
      ticks: {
        drawTicks: false,
        font: {
          size: 10,
          weight: 'bold',
        },
        padding: 6,
      },
      border: {
        display: false
      },
    },
    y: {
      ticks: {
        display: false,
        padding: 10,
      },
      grid: {
        beginAtZero: false,
        drawTicks: false,
      },
      border: {
        display: false
      },
    }
  }
}

export const milkOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  scales: {
    x: {
      display: false,
      max: (context: any) => {
        const datasets = context.chart.data.datasets;

        const maxSum = Math.max(
          ...datasets.map((item: any) => +item.data[0])
        );

        return maxSum * 1.1;
      },
      border: {
        display: false
      },
      grid: {
        display: false,
      }
    },
    y: {
      ticks: {
        display: false,
        border: {
          display: false
        }
      },
      grid: {
        drawTicks: false,
      }
    }
  },
  plugins: {
    legend: {
      ...legend,
      maxWidth: 110,
    },
    datalabels: {
      display: 'auto',
      font: {
        size: 10,
      },
      clamp: true,
      anchor: 'end',
      align: 'start',
      color: "#FFF"
    }
  },
}

export const cropAreaOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  scales: {
    x: {
      stacked: true,
      max: (context: any) => {
        const datasets = context.chart.data.datasets;
        const maxSum = Math.max(
          ...datasets[0].data.map((_: any, index: number) =>
            datasets.reduce((sum: number, dataset: any) => sum + +dataset.data[index], 0)
          )
        );
        return maxSum * 1.1;
      }
    },
    y: {
      stacked: true,
    }
  },
  plugins: {
    legend,
    datalabels: {
      display: (context: any) => context.datasetIndex === context.chart.data.datasets.length - 1,
      align: "end",
      anchor: "end",
      color: '#118F84',
      font: {
        weight: 'bold',
        size: 12,
      },
      formatter: (value: any, context: any) => {
        const datasets = context.chart.data.datasets;
        const total = datasets.reduce(
          (sum: number, dataset: any) => sum + +(dataset.data[context.dataIndex] || 0),
          0
        );
        return `${total} га`;
      },
    }
  }
}

export const milkProductionOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend,
    datalabels: {
      display: true,
      anchor: "end",
      align: "end",
      formatter: (value: any) => `${value}`,
      font: {
        size: 12,
        weight: "bold",
      },
      color: "#118F84",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false
      },
      ticks: {
        font: {
          size: 12,
        },
      },
    },
    y: {
      ticks: {
        display: false,
      },
      border: {
        display: false
      },
      grid: {
        drawTicks: false,
      },
      beginAtZero: true,
      max: (context: any) => {
        const datasets = context.chart.data.datasets;
        const maxValue = Math.max(
          ...datasets.flatMap((dataset: any) => dataset.data)
        );
        return maxValue * 1.25;
      },
    },
  },
};

interface IRegion {
  nameRu: string,
  nameKz: string,
  coordinates: [number, number],
  kato: number,
}

export enum SocialCategories {
  TSArecipients = 'TSArecipients',
  pensioners = 'pensioners',
  largeFamilies = 'largeFamilies',
  firstGroupDisabled = 'firstGroupDisabled',
  secondGroupDisabled = 'secondGroupDisabled',
  thirdGroupDisabled = 'thirdGroupDisabled',
}

export enum EmploymentCategories {
  hired_budget = 'hired_budget',
  hired_private = 'hired_private',
  selfEmployed = 'selfEmployed',
  unemployed = 'unemployed',
  workingElderly = 'workingElderly',
}
interface ISnp {
  nameRu: string,
  nameKz: string,
  kato: number,
  ppNameKz?: string,
  ppNameRu?: string,
  pnameRu?: string,
  pnameKz?: string,
  pkato?: number,
  ppKato?: number,
  code?: number,
  active?: boolean,
  fillingStatus?: 'COMPLETED' | 'IN_PROCESS' | 'NOT_STARTED',
}

interface ISettlement {
  name: string,
  children: any[],
  total: number,
  required: number,
  inProcess: number,
  completed: number,
  completionPercent: number,
  kato: number,
  fillingStatus?: 'COMPLETED' | 'IN_PROCESS' | 'NOT_STARTED',
  nameRu: string,
  nameKz: string,
  statistics: {
    completed: number,
    inProcess: number,
    required: number,
    completionPercent: number,
    total: number
  }
  status?: 'COMPLETED' | 'IN_PROCESS' | 'NOT_STARTED';
}

interface IMarker {
  code: number;
  latitude: string;
  longitude: string;
  nameKz: string;
  nameRu: string;
  type?: string;
  active?: boolean;
  fillingStatus?: 'COMPLETED' | 'IN_PROCESS' | 'NOT_STARTED',
  requiredFilling?: boolean
  kato?: number
}

interface ISettlementsObject {
  [districtName: string]: {
    [settlementName: string]: ISnp[];
  },

}

type IPeople = IPerson[];

interface IPerson {
  nameRu: string | null,
  nameKz: string | null,
  inPositionFrom: string | null,
  phone: string | null,
  imageId: number | null,
  positionKz: OfficialRoleType | null,
  positionRu: OfficialRoleType | null,
  type?: 's' | 'p'
}

type OfficialRoleType = "SUPERVISOR" | "POLICE";
type QualityType = 'GOOD' | 'SATISFACTORY' | 'BAD'
type CoverType = 'PAVED' | 'GRAVEL' | 'GROUND'

interface IGeneralInfo {
  kato: number;
  buildingNumber: number | null;
  supervisor: IPerson,
  police: IPerson,
  areaRoad: {
    cover: CoverType | null;
    distance: number | null;
    quality: QualityType | null;
  },
  regionRoad: {
    cover: CoverType | null;
    distance: number | null;
    quality: QualityType | null;
  },
  employment: {
    acpReceivers: number | null;
    employed: number | null;
    notEmployed: number | null;
    selfEmployed: number | null;
  },
  population: {
    all: number | null;
    kazakhs: number | null;
    others: number | null;
    russians: number | null;
  },
  status?: 'COMPLETED' | 'IN_PROCESS' | 'NOT_STARTED';
  akim?: any,
  basicStats?: any[];
  districtIncomeStats: IDistrictIncomeList | null;
  populationDynamic: number;
  populationIncreaseDynamicStats: IPopulationDynamic[];
  budgetInvestmentProjectStats: IInvestmentProject[];
  populationDemandStatsCounts: IPopulationDemand[];
  mediaMonitoringStats: IMediaMonitoringStat[];
  projectEstimateDocumentation: IPSDItem[];
  productSelfSufficiencyStats: IProductSufficiencyItem[];
  totalRoadShareStats: IRoadShare;
  populationDetailsStats: IPopulationDetails;
  taxStats: ITaxStat[];
  budgetInvestment: IBudgetInvestItem[];
}

interface IProject {
  constructionYear: number | null;
  documentationStatus: 'HAVE' | 'NOT_HAVE' | 'IN_PROCESS' | null | "";
  factInfo: number | null;
  id: number;
  images: any[] | null;
  kato: number;
  power: number | null;
  quality: string | null;
  requiredFund: any;
  requirements: string | null;
  sportSection: {
    [key: string]: string | null;
  }
  nameKz: string | null;
  nameRu: string | null;
  workDuration: string | null;
  [key: string]: any;
  lastMajorRenovation?: string | null;
}

interface OptionType {
  value: number;
  label: string;
  labelKz: string;
}

type DiagramType = 'studying' | 'road' | 'population' | 'nationality' | 'lifestock' | 'harvest' | 'finance' | 'employment' | 'education' | 'healthcare';

type FileDateType = 'IMAGE_AFTER' | 'IMAGE_CURRENT' | 'GENERAL_IMAGE'

interface IImage {
  fileId: number,
  fileType: FileDateType,
  date: Date | string,
}

interface IDistrictIncomeList {
  date: string,
  periodPlan: number,
  periodFact: number,
  periodImplementation: number,
  annualPlan: number,
  annualFact: number,
  annualImplementation: number,
}

interface IPopulationDynamic {
  year: number,
  value: number | null,
  id?: number,
}

interface IInvestmentProject {
  nameRu: string,
  nameKz: string,
  currentPrice: number,
  totalPrice: number,
  developmentShare: number,
  city: string,
  realizationDate: string,
  quarter: string,
}

interface IPopulationDemand {
  nameRu: string,
  nameKz: string,
  newConstructionRequirementCount: number,
  constructionRepairRequirementCount: number,
  normalConstructionCount: number,
  notProvidedCount: number
}

interface IMediaMonitoringStat {
  id: number,
  text: string,
  likeSum: number,
  commentSum: number,
  sharedSum?: number,
  url: string,
  socialNet?: string
}

interface IPSDItem {
  category: string,
  totalCount: number,
  governmentInvestorsCount: number,
  nonGovernmentInvestorsCount: number,
  quasiGovernmentInvestorsCount: number,
}

interface IProductSufficiencyItem {
  id: number;
  previousYearVal: number;
  currentYearVal: number;
  productNameRu: string;
  productNameKz: string;
  annualConsumptionPerPerson: number;
  annualConsumption: number;
  annualProductProduction: number;
  selfSufficiencyPercentage: number;
}

interface IBudgetInvestItem {
  id: number,
  devType: 'budget_razv' | 'tek_budget',
  plan: number,
  fact: number,
  plan2024: number,
  typeBudget: 'REGIONAL_BUDGET' | 'DEDICATED_REGIONAL_BUDGET',
}

interface IRoadShare {
  prevYearGoodConditionKm: string;
  prevYearGoodConditionPercentage: string;
  prevYearSatisfactoryConditionKm: string;
  prevYearSatisfactoryConditionPercentage: string;
  prevYearPoorConditionKm: string;
  prevYearPoorConditionPercentage: string;
  curYearGoodConditionKm: string;
  curYearGoodConditionPercentage: string;
  curYearSatisfactoryConditionKm: string;
  curYearSatisfactoryConditionPercentage: string;
  curYearPoorConditionKm: string;
  curYearPoorConditionPercentage: string;
  totalKm: string;
  curYearTotalFineRoadPercentage: string;
  prevYearTotalFineRoadPercentage: string
  roadShareStats: IRoadShareStats[],
}

interface IRoadShareStats {
  name: string;
  roadsList: IRoadShareItem[];
  prevYearTotalFineRoadPercentage?: string;
  curYearTotalFineRoadPercentage?: string;
  totalKm?: string;
  totalPrevYearGoodConditionKm?: string;
  totalPrevYearGoodConditionPercentage?: string;
  totalPrevYearSatisfactoryConditionKm?: string;
  totalPrevYearSatisfactoryConditionPercentage?: string;
  totalPrevYearPoorConditionKm?: string;
  totalPrevYearPoorConditionPercentage?: string;
  totalCurYearGoodConditionKm?: string;
  totalCurYearGoodConditionPercentage?: string;
  totalCurYearSatisfactoryConditionKm?: string;
  totalCurYearSatisfactoryConditionPercentage?: string;
  totalCurYearPoorConditionKm?: string;
  totalCurYearPoorConditionPercentage?: string;
}
interface IRoadShareItem {
  index: string;
  roadName: string;
  totalKm: string;
  prevYearGoodConditionKm: string;
  prevYearGoodConditionPercentage: string;
  prevYearSatisfactoryConditionKm: string;
  prevYearSatisfactoryConditionPercentage: string;
  prevYearPoorConditionKm: string;
  prevYearPoorConditionPercentage: string;
  curYearGoodConditionKm: string;
  curYearGoodConditionPercentage: string;
  curYearSatisfactoryConditionKm: string;
  curYearSatisfactoryConditionPercentage: string;
  curYearPoorConditionKm: string;
  curYearPoorConditionPercentage: string;
  ongoingProjects: string;
}

interface IPopulationDetails {
  id: number;
  populationCount: number;
  maleCount: number;
  femaleCount: number;
  birthCount: number;
  deathCount: number;
  immigrationCount: number;
  emigrationCount: number;
  saldoMigration: number;
  populationEmploymentCount: number;
  economicallyActivePopulation: number;
  youthPopulationCount: number;
  employedPopulationCount: number;
  hiredPopulationCount: number;
  selfEmployedPopulationCount: number;
  unemployedPopulationCount: number;
}

interface ITaxStat {
  id: number,
  type: 'REGIONAL_BUDGET' | 'PROPER_REGIONAL_BUDGET',
  fact: number,
  plan: number,
  annual: number,
  taxRevenuesFact: number,
  nonTaxRevenuesFact: number,
  fixedAssetsSaleFact: number,
  taxRevenuesPlan: number,
  nonTaxRevenuesPlan: number,
  fixedAssetsSalePlan: number,
  taxRevenuesAnnual: number,
  nonTaxRevenuesAnnual: number,
  fixedAssetsSaleAnnual: number,
  taxRevenuesPlanPercent: number,
  nonTaxRevenuesPlanPercent: number,
  fixedAssetsSalePlanPercent: number,
  taxRevenuesAnnualPercent: number,
  nonTaxRevenuesAnnualPercent: number,
  fixedAssetsSaleAnnualPercent: number,
  planPercent: number,
  annualPercent: number,
}

interface IScreeningInfo {
  humanResources: IHumanResources | null,
  landFund: ILandFund | null,
  smbDevelopment: IMCB | null,
  swotAnalysis: ISWOT | null,
  agriculture: IScreeningFarming | null,
}

interface IHumanResources {
  populationDynamic: IScreeningPopulationDynamic[] | null,
  populationByAge: IScreeningPopulationByAge[] | null,
  employment: IScreeningEmployment | null,
  populationBySocialStatus: IScreeningPopulationbySocialStatus | null,
}

interface IScreeningPopulationDynamic {
  year: number,
  population: number,
}

interface IScreeningPopulationByAge {
  age: '0-5' | '6-17' | '18-29' | '30-62' | '63 и старше',
  population: number,
}

interface IScreeningEmployment {
  [EmploymentCategories.hired_budget]: number,
  [EmploymentCategories.hired_private]: number,
  [EmploymentCategories.selfEmployed]: number,
  [EmploymentCategories.unemployed]: number,
  [EmploymentCategories.workingElderly]: number,
}

interface IScreeningPopulationbySocialStatus {
  [SocialCategories.TSArecipients]: number,
  [SocialCategories.pensioners]: number,
  [SocialCategories.largeFamilies]: number,
  [SocialCategories.firstGroupDisabled]: number,
  [SocialCategories.secondGroupDisabled]: number,
  [SocialCategories.thirdGroupDisabled]: number,
}

interface ILandFund {
  largeLandowners: ILargeLandowners[] | null,
  landFundItems: ILandFundItem | null
}

interface ILargeLandowners {
  name: string,
  total_land: number,
  total_arable: number,
  total_pasture: number,
  fact_land: number,
  fact_arable: number,
  fact_pasture: number,
}

interface ILandFundItem {
  settlementLand: string,
  agricultural_total: string,
  agricultural_arable: string,
  agricultural_pasture: string,
  agricultural_farmEnterprises: string,
  reserve_total: string,
  reserve_arable: string,
  reserve_pasture: string,
  otherLand: string
}

interface IMCB {
  mcbSubjects: IScreeningMCBSubjects | null,
  registeredSubjects: IScreeningRegisteredSubjects | null,
  totalSum: string | null,
  creditSupport: IScreeningMCBCreditSupport | null,
}

interface IScreeningMCBSubjects {
  total: string,
  TOO: string,
  IP: string,
  KH: string,
  SPK: string,
}

interface IScreeningRegisteredSubjects {
  juridical: string,
  agricultural: string,
  construction: string,
  retail: string,
  printing: string,
  finance: string,
  commerce: string,
  [key: string]: any,
}

interface IScreeningMCBCreditSupport {
  subsidizing_projects: string,
  subsidizing_sum: string,
  guarantee_projects: string,
  guarantee_sum: string,
  grant_projects: string,
  grant_sum: string,
}

interface ISWOT {
  strengths: ISWOTItem[] | null,
  weaknesses: ISWOTItem[] | null,
  opportunities: ISWOTItem[] | null,
  threats: ISWOTItem[] | null,
}

interface ISWOTItem {
  textRu: string,
  textKz: string,
}

interface IPSDAll {
  process: {
    process: string,
    count: number
  }[],
  category: {
    category: string,
    count: number
  }[],
  financing: {
    financingSource: 'GOVERNMENTAL_INVESTMENT' | 'NON_GOVERNMENTAL_INVESTMENT' | 'QUASI_GOVERNMENTAL_INVESTMENT',
    month: 0,
    count: 0
  }[]
}

interface IPSDFilter {
  projectName: string,
  customer: string,
  bin: string,
  objectLocation: string,
  examCompany: string,
  financingSource: string,
  designer: string,
  conclusionNumber: string,
  startDate: string,
  endDate: string
}

interface IPSDFilterList {
  projectNames: string[],
  customers: string[],
  bins: string[],
  objectLocations: string[],
  examLocations: string[],
  financingSources: string[],
  designers: string[],
  conclusionNumbers: string[],
  minDate: string[],
  maxDate: string[]
}

interface IPSDTop {
  client: {
    client: string,
    count: number
  }[],
  category: {
    category: string,
    count: number
  }[]
}

interface IFarmingItem {
  lph: string,
  kh: string,
}

interface IFarmingItemsList {
  KPC: IFarmingItem,
  MPC: IFarmingItem,
  horses: IFarmingItem,
  camels: IFarmingItem,
}

interface IFarmingAreaItem {
  vegetables: string,
  grains: string,
  potato: string,
  carnival: string,
  forage: string,
}

interface IPoultryList {
  goose: IFarmingItem,
  duck: IFarmingItem,
  chicken: IFarmingItem,
}

interface IScreeningFarming {
  animalHusbandry: IFarmingItemsList,
  agriculturalGrowth: {
    KPC: { year: number, value: string }[],
    MPC: { year: number, value: string }[],
    horses: { year: number, value: string }[],
    birds: { year: number, value: string }[],
    camels: { year: number, value: string }[],
  },
  selfSufficiency: {
    sheep: string,
    bird: string,
    egg: string,
    cucumber: string,
    tomato: string,
  },
  poultry: IPoultryList,
  meat: IFarmingItemsList,
  milk: {
    dairy: string,
    milkProduction: string,
  },
  cropArea: {
    kh: IFarmingAreaItem,
    lph: IFarmingAreaItem,
  },
  yearlyMeat: IFarmingItemsList,
  milkProduction: {
    butter: {
      yearly: string,
      average: string,
    },
    cottage: {
      yearly: string,
      average: string,
    },
    sourCream: {
      yearly: string,
      average: string,
    },
    kefir: {
      yearly: string,
      average: string,
    },
    milk: {
      yearly: string,
      average: string,
    }
  },
  harvest: {
    kh: IFarmingAreaItem,
    lph: IFarmingAreaItem,
  }
}

export type {
  IRegion,
  ISnp,
  ISettlement,
  IMarker,
  ISettlementsObject,
  OfficialRoleType,
  IGeneralInfo,
  IProject,
  IPeople,
  IPerson,
  OptionType,
  DiagramType,
  FileDateType,
  QualityType,
  CoverType,
  IImage,
  IDistrictIncomeList,
  IPopulationDynamic,
  IInvestmentProject,
  IPopulationDemand,
  IMediaMonitoringStat,
  IPSDItem,
  IProductSufficiencyItem,
  IRoadShareItem,
  IRoadShareStats,
  IRoadShare,
  IPopulationDetails,
  ITaxStat,
  IScreeningInfo,
  IHumanResources,
  IScreeningPopulationDynamic,
  IScreeningPopulationByAge,
  IScreeningEmployment,
  IScreeningPopulationbySocialStatus,
  ILandFund,
  ILargeLandowners,
  ILandFundItem,
  IMCB,
  IScreeningMCBSubjects,
  IScreeningRegisteredSubjects,
  ISWOT,
  IScreeningMCBCreditSupport,
  ISWOTItem,
  IBudgetInvestItem,
  IPSDAll,
  IPSDFilter,
  IPSDTop,
  IPSDFilterList,
  IFarmingItem,
  IFarmingItemsList,
  IFarmingAreaItem,
  IScreeningFarming,
  IPoultryList
}



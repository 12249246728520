import React, { FC, Fragment, useEffect } from 'react'
import { BasicStatsItem } from './index.styles';
import { Text } from '../text.component';
import { useTranslation } from 'react-i18next';
import { ExpandCard } from '../../assets/icons';
import { IMediaMonitoringStat } from '../../interfaces/snp.interface';
import { CommentIcon, FacebookIcon, InstagramIcon, LikeIcon, ShareIcon, TGIcon, VKIcon, YTIcon } from '../../assets/icons/mm';

interface IProps {
  list: IMediaMonitoringStat[];
  regionName: string;
}

const valueToIcon = {
  1: <InstagramIcon />,
  2: <FacebookIcon />,
  3: <VKIcon />,
  4: <YTIcon />,
  5: <TGIcon />
}

const MediaMonitoring: FC<IProps> = ({ list, regionName }) => {
  const { t } = useTranslation();
  const [sortedList, setSortedList] = React.useState<IMediaMonitoringStat[]>([]);

  const handleLinkClick = () => {
    window.open(`https://mm.csi.kz/main?keyword=${regionName.split(' ')[0].toLowerCase()}&clientid=aktobe-ea&realm=aktobe-sic`, '_blank');
  }

  useEffect(() => {
    if (list) {
      setSortedList(list.sort((a, b) => b.likeSum - a.likeSum).slice(0, 3));
    }
  }, [list]);

  return (
    <BasicStatsItem id="mm">
      <div className="title with-icon">
        <div className="left">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`media-monitoring.title`)}</Text>
        </div>
        <div className="right">
          <span className='unit-container' >
            <ExpandCard onClick={() => handleLinkClick()} />
          </span>
        </div>
      </div>
      <div className="body monitoring-body">
        <div></div>
        <LikeIcon />
        <CommentIcon />
        <ShareIcon className='share-icon' />

        {
          sortedList && sortedList.length > 0 && sortedList.map((item: IMediaMonitoringStat) => (
            <Fragment key={item.id}>
              <div className="text">
                {item.socialNet && valueToIcon[+item.socialNet as keyof typeof valueToIcon]}
                <a href={item.url} target='_blank' rel="noreferrer" color='var(--mm-link)'>{item.text}</a>
              </div>
              <Text fontSize='0.75rem' color='var(--primary-color)'>{item.likeSum || 0}</Text>
              <Text fontSize='0.75rem' color='var(--primary-color)'>{item.commentSum || 0}</Text>
              <Text fontSize='0.75rem' color='var(--primary-color)'>{item.sharedSum || 0}</Text>
            </Fragment>
          ))
        }
      </div>
    </BasicStatsItem>
  )
}

export default MediaMonitoring
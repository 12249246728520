import React, { FC, useEffect, useState } from 'react'
import { BasicStatsItem } from '../index.styles'
import { Text } from '../../text.component'
import { Line, Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChickenIcon, CucumberIcon, EggIcon, LambIcon, TomatoIcon } from '../../../assets/icons';
import { useParams } from 'react-router-dom';
import { IScreeningFarming } from '../../../interfaces/snp.interface';
import { agriculturalGrowthOptions, animalHusbandryOptions, cropAreaOptions, milkOptions, milkProductionOptions } from './chart-options';
import { currentYear } from '../../../constants/snp.constant';
import { formatNumberForScreening, splitChartLabels } from '../../../utils/helpers.utils';
import i18n from '../../../i18n';

const keyToIcon: Record<keyof IScreeningFarming['selfSufficiency'], any> = {
  'sheep': <LambIcon />,
  'bird': <ChickenIcon />,
  'egg': <EggIcon />,
  'cucumber': <CucumberIcon />,
  'tomato': <TomatoIcon />
}



interface IProps {
  data: IScreeningFarming
}

const FarmingTab: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();
  const { kato } = useParams();
  const { language } = i18n;

  const [animalHusbandry, setAnimalHusbandry] = useState<any>();
  const [agriculturalGrowth, setAgriculturalGrowth] = useState<any>();
  const [selfSufficiency, setSelfSufficiency] = useState<any>();
  const [poultry, setPoultry] = useState<any>();
  const [meat, setMeat] = useState<any>();
  const [milk, setMilk] = useState<any>();
  const [cropArea, setCropArea] = useState<any>();
  const [yearlyMeat, setYearlyMeat] = useState<any>();
  const [milkProduction, setMilkProduction] = useState<any>();
  const [harvest, setHarvest] = useState<any>();

  const buildData = (data: any, key: string) => {
    return {
      labels: ['KPC', 'MPC', 'horses', 'camels'].map(item => t(`screening-page.agriculture.${key}.${item}`)),
      datasets: [
        {
          label: t(`screening-page.lph`),
          data: [formatNumberForScreening(data.KPC.lph), formatNumberForScreening(data.MPC.lph), formatNumberForScreening(data.horses.lph), formatNumberForScreening(data.camels.lph)],
          backgroundColor: '#009187',
          borderWidth: 0,
          borderRadius: 10,
          barThickness: 65,
        },
        {
          label: t(`screening-page.kh`),
          data: [formatNumberForScreening(data.KPC.kh), formatNumberForScreening(data.MPC.kh), formatNumberForScreening(data.horses.kh), formatNumberForScreening(data.camels.kh)],
          backgroundColor: '#7dd259',
          borderWidth: 0,
          borderRadius: 10,
          barThickness: 65,
        },
        {
          label: '',
          data: [0, 0, 0, 0],
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          borderWidth: 0,
          borderRadius: 10,
        }
      ]
    }
  }

  const buildHarvest = (data: any) => ({
    labels: ['kh', 'lph'].map(item => t(`screening-page.${item}`)),
    datasets: [
      {
        label: t('screening-page.agriculture.cropArea.chart-vegetables'),
        data: [formatNumberForScreening(data.kh.vegetables), formatNumberForScreening(data.lph.vegetables)],
        backgroundColor: "#118F84",
        barThickness: 40,
        borderRadius: 5,
      },
      {
        label: t('screening-page.agriculture.cropArea.chart-grains'),
        data: [formatNumberForScreening(data.kh.grains), formatNumberForScreening(data.lph.grains)],
        backgroundColor: "#92D050",
        barThickness: 40,
        borderRadius: 5,
      },
      {
        label: t('screening-page.agriculture.cropArea.chart-potato'),
        data: [formatNumberForScreening(data.kh.potato), formatNumberForScreening(data.lph.potato)],
        backgroundColor: "#595959",
        barThickness: 40,
        borderRadius: 5,
      },
      {
        label: t('screening-page.agriculture.cropArea.chart-carnival'),
        data: [formatNumberForScreening(data.kh.carnival), formatNumberForScreening(data.lph.carnival)],
        backgroundColor: "#5BC7D9",
        barThickness: 40,
        borderRadius: 5,
      },
      {
        label: t('screening-page.agriculture.cropArea.chart-forage'),
        data: [formatNumberForScreening(data.kh.forage), formatNumberForScreening(data.lph.forage)],
        backgroundColor: "#6968FD",
        barThickness: 40,
        borderRadius: 5,
      },
    ]
  })

  const buildGraphs = (data: IScreeningFarming) => {
    if (data) {
      const { animalHusbandry, agriculturalGrowth, selfSufficiency, poultry, meat, milk, cropArea, yearlyMeat, milkProduction } = data;

      setAnimalHusbandry(buildData(animalHusbandry, 'animalHusbandry'));

      setAgriculturalGrowth({
        labels: Array.from({ length: 3 }, (_, i) => currentYear + i - 3),
        datasets: [
          {
            label: t('screening-page.agriculture.agriculturalGrowth.chart-MPC'),
            data: agriculturalGrowth.MPC.map((item: any) => item.value),
            borderColor: '#009187',
            backgroundColor: '#009187',
            tension: 0.1,
            borderWidth: 1.5,
          },
          {
            label: t('screening-page.agriculture.agriculturalGrowth.chart-KPC'),
            data: agriculturalGrowth.KPC.map((item: any) => item.value),
            borderColor: '#7dd259',
            backgroundColor: '#7dd259',
            tension: 0.1,
            borderWidth: 1.5,
          },
          {
            label: t('screening-page.agriculture.agriculturalGrowth.chart-horses'),
            data: agriculturalGrowth.horses.map((item: any) => item.value),
            borderColor: '#00cadc',
            backgroundColor: '#00cadc',
            tension: 0.1,
            borderWidth: 1.5,
          },
          {
            label: t('screening-page.agriculture.agriculturalGrowth.chart-birds'),
            data: agriculturalGrowth.birds.map((item: any) => item.value),
            borderColor: '#006ec3',
            backgroundColor: '#006ec3',
            tension: 0.1,
            borderWidth: 1.5,
          },
          {
            label: t('screening-page.agriculture.agriculturalGrowth.chart-camels'),
            data: agriculturalGrowth.camels.map((item: any) => item.value),
            borderColor: '#595959',
            backgroundColor: '#595959',
            tension: 0.1,
            borderWidth: 1.5,
          },
        ]
      });

      const sortedEntries = Object.fromEntries(Object.entries(selfSufficiency).sort(
        ([, valueA], [, valueB]) => Number(valueB) - Number(valueA)
      ));

      setSelfSufficiency(sortedEntries);

      setPoultry({
        labels: ['goose', 'duck', 'chicken'].map(item => t(`screening-page.agriculture.poultry.${item}`)),
        datasets: [
          {
            label: t(`screening-page.lph`),
            data: [formatNumberForScreening(poultry.goose.lph), formatNumberForScreening(poultry.duck.lph), formatNumberForScreening(poultry.chicken.lph)],
            backgroundColor: '#009187',
            borderWidth: 0,
            borderRadius: 10,
            barThickness: 65,
          },
          {
            label: t(`screening-page.kh`),
            data: [formatNumberForScreening(poultry.goose.kh), formatNumberForScreening(poultry.duck.kh), formatNumberForScreening(poultry.chicken.kh)],
            backgroundColor: '#7dd259',
            borderWidth: 0,
            borderRadius: 10,
            barThickness: 65,
          },
          {
            label: '',
            data: [0, 0, 0],
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            borderWidth: 0,
            borderRadius: 10,
          }
        ]
      });

      setMeat(buildData(meat, 'meat'));

      setMilk({
        labels: [1],
        datasets: [
          {
            label: t('screening-page.agriculture.milk.dairy'),
            data: [formatNumberForScreening(milk.dairy)],
            backgroundColor: '#7dd259',
            borderWidth: 1,
            borderRadius: 5,
            barPercentage: 0.75,
          },
          {
            label: t('screening-page.agriculture.milk.milkProduction'),
            data: [formatNumberForScreening(milk.milkProduction)],
            backgroundColor: '#009187',
            borderWidth: 1,
            borderRadius: 5,
            barPercentage: 0.75,
          }
        ]
      });

      setCropArea(buildHarvest(cropArea));

      setYearlyMeat(buildData(yearlyMeat, 'yearlyMeat'));

      setMilkProduction(
        {
          labels: splitChartLabels(['butter', 'cottage', 'sourCream', 'kefir', 'milk'].map(item => t(`screening-page.agriculture.milkProduction.${item}`))),
          datasets: [
            {
              label:  t(`screening-page.agriculture.milkProduction.chart-yearly`),
              data: [formatNumberForScreening(milkProduction.butter.yearly), formatNumberForScreening(milkProduction.cottage.yearly), formatNumberForScreening(milkProduction.sourCream.yearly), formatNumberForScreening(milkProduction.kefir.yearly), formatNumberForScreening(milkProduction.milk.yearly)],
              backgroundColor: "#92D050",
              borderRadius: 5,
              barPercentage: 0.6,
              categoryPercentage: 0.75,
            },
            {
              label:  t(`screening-page.agriculture.milkProduction.chart-average`),
              data: [formatNumberForScreening(milkProduction.butter.average), formatNumberForScreening(milkProduction.cottage.average), formatNumberForScreening(milkProduction.sourCream.average), formatNumberForScreening(milkProduction.kefir.average), formatNumberForScreening(milkProduction.milk.average)],
              backgroundColor: "#118F84",
              borderRadius: 5,
              barPercentage: 0.6,
              categoryPercentage: 0.75,
            },
          ],
        }
      )

      setHarvest(buildHarvest(data.harvest));
    }
  }

  useEffect(() => {
    buildGraphs(data);
  }, [data, kato, language])

  return (
    <div className='farming'>
      {
        animalHusbandry && <BasicStatsItem>
          <div className="title">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`screening-page.agriculture.animalHusbandry.title`)}</Text>
          </div>
          <div className="body" style={{ padding: '0.3125rem' }}>
            <div className="line-chart-container" style={{ height: '100%' }}>
              <Bar data={animalHusbandry} options={animalHusbandryOptions} plugins={[ChartDataLabels]} />
            </div>
          </div>
        </BasicStatsItem>
      }

      {
        agriculturalGrowth && <BasicStatsItem>
          <div className="title">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`screening-page.agriculture.agriculturalGrowth.title`)}</Text>
          </div>
          <div className="body">
            <div className="line-chart-container" style={{ height: '100%' }}>
              <Line data={agriculturalGrowth} options={agriculturalGrowthOptions} plugins={[ChartDataLabels]} />
            </div>
          </div>
        </BasicStatsItem>
      }

      {
        selfSufficiency && <BasicStatsItem>
          <div className="title">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`screening-page.agriculture.selfSufficiency.title`)}</Text>
          </div>
          <div className="body">
            {Object.keys(selfSufficiency).map((key: string) => (
              <div className={`body__item with-icon stretched FOOD`} key={key}>
                <div className="icon-container">
                  <div className="icon">{keyToIcon[key as keyof IScreeningFarming['selfSufficiency']]}</div>
                  <Text fontSize='0.75rem' color='var(--text-color)' fontWeight={400}>
                    {t(`new-fields.${key.toUpperCase()}`)}
                  </ Text>
                </div>
                <div className="increase">
                  <Text className='percent-value' fontSize={'1rem'} color='var(--primary-color)' fontWeight={700}>
                    {selfSufficiency[key as keyof IScreeningFarming['selfSufficiency']] ?? 0}%
                  </Text>
                </div>
              </div>
            ))}
          </div>
        </BasicStatsItem>
      }

      {
        poultry && <BasicStatsItem>
          <div className="title">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`screening-page.agriculture.poultry.title`)}</Text>
          </div>
          <div className="body" style={{ padding: '0.3125rem' }}>
            <div className="line-chart-container" style={{ height: '100%', maxHeight: '18rem' }}>
              <Bar data={poultry} options={animalHusbandryOptions} plugins={[ChartDataLabels]} />
            </div>
          </div>
        </BasicStatsItem>
      }

      {
        meat && <BasicStatsItem>
          <div className="title">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`screening-page.agriculture.meat.title`)}</Text>
          </div>
          <div className="body" style={{ padding: '0.3125rem' }}>
            <div className="line-chart-container" style={{ height: '100%' }}>
              <Bar data={meat} options={animalHusbandryOptions} plugins={[ChartDataLabels]} />
            </div>
          </div>
        </BasicStatsItem>
      }

      {
        milk && <BasicStatsItem>
          <div className="title">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`screening-page.agriculture.milk.title`)}</Text>
          </div>
          <div className="body" style={{ padding: '0.3125rem' }}>
            <div className="line-chart-container">
              <Bar data={milk} options={milkOptions} plugins={[ChartDataLabels]} />
            </div>
          </div>
        </BasicStatsItem>
      }

      {
        cropArea && <BasicStatsItem>
          <div className="title">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`screening-page.agriculture.cropArea.title`)}</Text>
          </div>
          <div className="body" style={{ padding: '0.3125rem' }}>
            <div className="line-chart-container" style={{ height: '100%', maxHeight: '18rem' }}>
              <Bar data={cropArea} options={cropAreaOptions} plugins={[ChartDataLabels]} />
            </div>
          </div>
        </BasicStatsItem>
      }

      {
        yearlyMeat && <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`screening-page.agriculture.yearlyMeat.title`)}</Text>
              <Text color='#818689' fontWeight={400} fontSize='0.75rem'>тонн</Text>
            </div>
          </div>
          <div className="body" style={{ padding: '0.3125rem' }}>
            <div className="line-chart-container" style={{ height: '100%' }}>
              <Bar data={yearlyMeat} options={animalHusbandryOptions} plugins={[ChartDataLabels]} />
            </div>
          </div>
        </BasicStatsItem>
      }

      {
        milkProduction && <BasicStatsItem>
          <div className="title">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`screening-page.agriculture.milkProduction.title`)}</Text>
          </div>
          <div className="body" style={{ padding: '0.3125rem' }}>

            <div className="line-chart-container" style={{ height: '100%' }}>
              <Bar data={milkProduction} options={milkProductionOptions} plugins={[ChartDataLabels]} />
            </div>
          </div>
        </BasicStatsItem>
      }

      {
        harvest && <BasicStatsItem>
          <div className="title">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`screening-page.agriculture.harvest.title`)}</Text>
          </div>
          <div className="body" style={{ padding: '0.3125rem' }}>
            <div className="line-chart-container" style={{ height: '100%', maxHeight: '18rem' }}>
              <Bar data={harvest} options={cropAreaOptions} plugins={[ChartDataLabels]} />
            </div>
          </div>
        </BasicStatsItem>
      }
    </div>
  )
}

export default FarmingTab
import styled, { css } from "styled-components"
import { FormGroup } from "../admin-page/admin-page.styles";

const InfrastructureTabs = styled.div`
  display: flex;
  gap: 0.9375rem;
  width: 100%;
  padding: 0.3125rem 0.625rem;
  align-items: center;
  border-radius: 0.3125rem;
  background: var(--lightYellow);
  max-width: 100%;
  overflow-x: auto;
  height: 2rem;
  margin-bottom: 0.9375rem;

  ::-webkit-scrollbar {
    height: 2px; 
    margin-bottom: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin-bottom: 0.625rem; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray); 
    border-radius: 5px;
    border-left: 15px solid transparent; 
    border-right: 35px solid transparent;
    background-clip: padding-box;
  }
`;

const InfrastructureTab = styled.div<{ selected?: boolean }>`
  color: ${({ selected }) => selected ? 'var(--text-color)' : 'var(--shuttleGray)'};
  font-weight: ${({ selected }) => selected ? ' 700' : '400'};
  border-bottom: ${({ selected }) => selected ? ' 1px solid var(--text-color)' : 'none'};
  font-size: 0.75rem;
  cursor: pointer;  
  white-space: nowrap;
`;

const EducationReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  max-width: 100%;
  overflow-y: auto;
  max-height: 35rem;
  padding-right: 0.625rem;
  ::-webkit-scrollbar {
    width: 2px;
    margin-right: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; 
    margin-right: 0.625rem;

  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;
  }
`

const EducationObject = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid var(--borderGray);
  background: var(--white);
  padding: 0.625rem 0.9375rem;
  cursor: pointer;
  &:nth-child(2n) {
    background: var(--whiteSmoke);
  }

  svg path {
    stroke: var(--gray);
    cursor: pointer;
  }
`;

const AddEducationObject = styled.button`
  all: unset;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--white);
  display: flex;
  gap: 0.625rem;
  padding: 0.5rem 0.9375rem;
  border-radius: 5px;
  background: var(--uploadBlue);
  align-self: flex-start;
  cursor: pointer;
  align-items: center;

  &:disabled {
    background: var(--gray);
    cursor: default;
  }
`;

const FormBlock = styled.div<{ isStreet?: boolean }>`
  border-radius: 5px;
  background: var(--whiteSmoke);
  padding: 0.3125rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-bottom: 1.25rem;

  .title {
    color: var(--shuttleGray);
    font-size:  0.75rem;;
  }

  .row {
    display: flex;
    gap: 1.25rem;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
   
    ${FormGroup} {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.625rem;  

      label {
        white-space: nowrap;
      }

      input {
        height: 1.8125rem;
        max-width: 8rem;
      }
    }

    &.sb {
      ${FormGroup} {
        width: 100%;
        input,
        select {
          height: 1.8125rem;
          max-width: 100%;
          flex: 1;
        }
      }
    }


    &.wrap {
      ${FormGroup} {
        justify-content: space-between;
        label {
          max-width: 33%;
          white-space: initial;
        }

        input,
        select {
          max-width: 20rem;
        }
      }
    }

    &.sport {
      ${FormGroup} {
        justify-content: space-between;
        input {
          max-width: 15rem;
        }
      }
    }
  }

  ${({ isStreet }) => isStreet && css`
    position: relative;
    .delete-street {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 1rem;
      cursor: pointer;
      z-index: 10;
    }
  `}

  ${FormGroup} {
    margin-bottom: 0;

    input {
      font-weight: 400;
      font-size: 0.75rem;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.625rem;

    ${FormGroup} {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
    }

    input {
      max-width: 9rem;
    }

    &.roads {
      input,
      select {
        max-width: 8rem;
      }
    }
  }

  .add-info__buttons{
    display: flex;
    gap: 0.625rem;
  }
`;

const FormBlockButton = styled.div`
  all: unset;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  color: var(--uploadBlue);
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
`;

const InfrastructureButtonsContainer = styled.div`
  display: flex;
  gap: 0.625rem;
  margin-top: 0.625rem;
  align-items: center;

  .checkbox-container{
    display: flex;
    align-items: center;
    gap: 0.625rem;
    font-size: 0.875rem;
    font-weight: 700;
    cursor: pointer;
  }
`;

export {
  InfrastructureTabs,
  InfrastructureTab,
  EducationReportContainer,
  EducationObject,
  AddEducationObject,
  FormBlock,
  FormBlockButton,
  InfrastructureButtonsContainer
}

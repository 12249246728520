import React, { FC, useEffect, useState } from 'react'
import { SERContainer, SERTable } from './index.styles'
import { Text } from '../../text.component'
import { SortIcon2, TrendDown, TrendUp } from '../../../assets/icons'
import { SER1, SER10, SER11, SER12, SER13, SER16, SER17, SER18, SER19, SER2, SER20, SER21, SER22, SER23, SER24, SER25, SER26, SER27, SER28, SER29, SER3, SER30, SER4, SER5, SER6, SER7 } from '../../../assets/icons/ser'
import { isRegion } from '../../../utils/helpers.utils'
import { OBLAST_KATO } from '../../../constants/snp.constant'
import i18n from '../../../i18n'
import { useTranslation } from 'react-i18next'

const SERIcons: any = {
  'INDUSTRIAL_PRODUCTION': <SER1 />,
  'MINING_INDUSTRY': <SER2 />,
  'MANUFACTURING_INDUSTRY': <SER3 />,
  'AGRICULTURE': <SER4 />,
  'INVESTMENT': <SER5 />,
  'HOUSE_COMMISSIONING': <SER6 />,
  'CONSTRUCTION': <SER7 />,
  'TRANSPORT_WAREHOUSING': <SER29 />,
  'CONNECTION': <SER30 />,
  'INCOME_RECEIPT': <SER10 />,
  'BUDGET_EXPENDITURE': <SER10 />,
  'CURRENT_MSP': <SER11 />,
  'RETAIL': <SER12 />,
  'WHOLESALE': <SER13 />,
  'EMPLOYED': <SER16 />,
  'UNEMPLOYED': <SER17 />,
  'NEW_WORKPLACE': <SER18 />,
  'AVERAGE_MONTHLY_SALARY': <SER19 />,
  'KRC': <SER20 />,
  'SHEEP': <SER21 />,
  'GOAT': <SER22 />,
  'HORSE': <SER23 />,
  'CAMEL': <SER24 />,
  'BIRD': <SER25 />,
  'MEAT': <SER26 />,
  'COW_MILK': <SER27 />,
  'CHICKEN_EGG': <SER28 />,
}

const order = [
  'INVESTMENT',
  'INDUSTRIAL_PRODUCTION',
  'MINING_INDUSTRY',
  'MANUFACTURING_INDUSTRY',
  'CONSTRUCTION',
  'AGRICULTURE',
  'TRANSPORT_WAREHOUSING',
  'HOUSE_COMMISSIONING',
]

const tabs = ['SER', 'BUDGET', 'MCB', 'EMPLOYMENT', 'LIVESTOCK'];

interface IDataItem {
  subType: string;
  currentYearVal?: number;
  increaseVal?: number;
  additionalInfo?: {
    place?: string;
    development?: string;
    IFOPlace?: string;
  };
  ifo?: number;
  ifoPlace?: string;
}

interface IData {
  SER?: IDataItem[];
  BUDGET?: IDataItem[];
  MCB?: IDataItem[];
  EMPLOYMENT?: IDataItem[];
  LIVESTOCK?: IDataItem[];
}

interface IProps {
  DATA: IData;
  kato: string | number;
}

const SER: FC<IProps> = ({ DATA, kato }) => {
  const { t } = useTranslation();
  const { language } = i18n;
  const [data, setData] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const config = {
    SER: [
      [
        {
          key: 'name',
          label: t('ser.name'),
          visible: true,
          colSpan: 2,
          rowSpan: 2,
        },
        {
          key: 'ifo',
          label: t('ser.ifo'),
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'increase',
          label: t('ser.increase'),
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'value',
          label: t('ser.value'),
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'regionPlace',
          label: t('ser.regionPlace', { type: +kato === OBLAST_KATO ? t('ser.regions') : t('ser.districts') }),
          visible: true,
          colSpan: 2,
          rowSpan: 1,
        }
      ],
      [
        {
          key: 'ifoPlace',
          label: t('ser.ifoPlace'),
          visible: true,
        },
        {
          key: 'place',
          label: t('ser.place', { type: +kato === OBLAST_KATO ? 'млр.' : 'млн.' }),
          visible: true,
        }
      ]
    ],
    BUDGET: [
      [
        {
          key: 'name',
          label: t('ser.name'),
          visible: true,
          colSpan: 2,
          rowSpan: 2,
        },
        {
          key: 'value',
          label: t('ser.value'),
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'development',
          label: t('ser.development'),
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'increase',
          label: t('ser.increase'),
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'regionPlace',
          label: t('ser.regionPlace', { type: t('ser.districts') }),
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
          colSpan: 2,
          rowSpan: 1,
        }
      ],
      [
        {
          key: 'ifoPlace',
          label: t('ser.ifoPlace'),
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
        },
        {
          key: t('ser.place', { type: +kato === OBLAST_KATO ? 'млр.' : 'млн.' }),
          label: +kato === OBLAST_KATO ? 'Объем, млр., тенге' : 'Объем, млн., тенге',
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
        }
      ]
    ],
    OTHER: [
      [
        {
          key: 'name',
          label: t('ser.name'),
          visible: true,
          colSpan: 2,
          rowSpan: 2,
        },
        {
          key: 'ifo',
          label: t('ser.ifo'),
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'increase',
          label: t('ser.increase'),
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'value',
          label: t('ser.value'),
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'regionPlace',
          label: t('ser.regionPlace', { type: t('ser.districts') }),
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
          colSpan: 2,
          rowSpan: 1,
        }
      ],
      [
        {
          key: 'ifoPlace',
          label: t('ser.ifoPlace'),
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
        },
        {
          key: 'place',
          label: t('ser.place', { type: +kato === OBLAST_KATO ? 'млр.' : 'млн.' }),
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
        }
      ]
    ]
  }

  const handleSort = (key: string) => {
    if (sortKey === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }

    setData((prevData: any) => ({
      ...prevData,
      [selectedTab]: prevData[selectedTab]?.slice().sort((a: IDataItem, b: IDataItem) => {
        const aValue = a[key as keyof IDataItem] ?? '';
        const bValue = b[key as keyof IDataItem] ?? '';

        if (key === 'name' && typeof aValue === 'string' && typeof bValue === 'string') {
          if (sortDirection === 'asc') {
            return aValue.localeCompare(bValue);
          } else {
            return bValue.localeCompare(aValue);
          }
        } else {
          const aNum = +(aValue.toString().replaceAll(',', '.')) || 0;
          const bNum = +(bValue.toString().replaceAll(',', '.')) || 0;


          if (sortDirection === 'asc') {
            return aNum - bNum;
          } else {
            return bNum - aNum;
          }
        }
      }),
    }));
  };

  const getTHead = () => {
    if (['SER', 'BUDGET', 'MCB', 'EMPLOYMENT', 'LIVESTOCK'].includes(selectedTab)) {
      return (
        <thead>
          <tr>
            {(['SER', 'BUDGET'].includes(selectedTab) ? config[selectedTab as keyof typeof config] : config['OTHER'])[0].map((item: any, index: number) => (
              item.visible && <th
                key={index}
                onClick={() => handleSort(item.key)}
                colSpan={item.colSpan || 1}
                rowSpan={item.rowSpan || 1}
                className={item.key === 'regionPlace' ? 'no-border' : ''}
              >
                <div>
                  <Text fontSize='0.75rem' color='#7C7E7C' fontWeight={400}>{item.label}</Text>
                  {item.key !== 'regionPlace' && <SortIcon2 />}
                </div>
              </th>
            ))}
          </tr>
          <tr className='no-border'>
            {(['SER', 'BUDGET'].includes(selectedTab) ? config[selectedTab as keyof typeof config] : config['OTHER'])[1].map((item: any, index: number) => (
              item.visible && <th key={index}>
                <Text fontSize='0.75rem' color='#000105' fontWeight={400}>{item.label}</Text>
              </th>
            ))}
          </tr>
        </thead>
      );
    }
    return <thead></thead>;
  }

  const renderTableBody = (items: any[], additionalFields: boolean = false) => {
    return (
      <tbody>
        {items.map((item: any, index: number) => (
          <tr key={index}>
            <td>{item.icon}</td>
            <td>{item.name}</td>
            <td>{item.ifo || '-'}</td>
            <td><Text
              fontSize='0.75rem'
              color={item.increase > 0 ? "var(--success-green)" : "var(--failure-red)"}
              fontWeight={500}
              style={{ minWidth: '11rem' }}>
              {item.increase > 0 ? <TrendUp width={10} height={8} /> : <TrendDown width={10} height={8} />}
              {' '}{item.increase} {item.add || '%'}
            </Text></td>
            {
              selectedTab === 'BUDGET' ? <td><Text
                fontSize='0.75rem'
                color={+item.development > 0 ? "var(--success-green)" : "var(--failure-red)"}
                fontWeight={500}
                style={{ minWidth: '11rem' }}>
                {+item.development > 0 ? <TrendUp width={10} height={8} /> : <TrendDown width={10} height={8} />}
                {' '}{+item.development} {item.add || '%'}
              </Text></td>
                : <td>{+item.development || '-'}  {item.add}</td>
            }
            {additionalFields && (
              <>
                <td>{item.ifoPlace || '-'} {+kato !== OBLAST_KATO ? '/ 13' : '/ 20'}</td>
                <td>{item.place || '-'} {+kato !== OBLAST_KATO ? '/ 13' : '/ 20'}</td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    );
  };

  const getTBody = () => {
    if (selectedTab === 'SER') {
      return renderTableBody(data.SER, true);
    } else if (selectedTab === 'BUDGET') {
      return renderTableBody(data.BUDGET, +kato !== OBLAST_KATO && isRegion(+kato));
    } else if (['MCB', 'EMPLOYMENT', 'LIVESTOCK'].includes(selectedTab)) {
      return renderTableBody(data[selectedTab], +kato !== OBLAST_KATO && isRegion(+kato));
    }
    return <tbody></tbody>;
  };

  useEffect(() => {
    if (DATA) {
      const SER = order.map((key: string) => {
        const item = DATA.SER?.find((item: any) => item.subType === key)
        if (item) {
          return {
            ...item,
            value: item.currentYearVal?.toLocaleString() || '-',
            increase: item.increaseVal || '-',
            place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
            icon: SERIcons[key] || null,
            name: t('SER-GENERAL.' + key),
            add: key === 'HOUSE_COMMISSIONING' ? 'кв. м.' : '',
            ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
            ifo: item.ifo?.toString() || '-',
          }
        }
      })

      const BUDGET = DATA.BUDGET?.map((item: any) => ({
        ...item,
        value: item.currentYearVal?.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        development: item.additionalInfo?.development || '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo?.toString() || '-',
      }))

      const MCB = DATA.MCB?.map((item: any) => ({
        ...item,
        value: item.currentYearVal?.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        add: item.subType === 'CURRENT_MSP' ? 'ед.' : '',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo?.toString() || '-',
      }))

      const EMPLOYMENT = DATA.EMPLOYMENT?.map((item: any) => ({
        ...item,
        value: item.currentYearVal?.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        add: item.subType === 'AVERAGE_MONTHLY_SALARY'
          ? 'тенге'
          : item.subType === 'NEW_WORKPLACE'
            ? 'ед.'
            : 'чел.',
        increaseAdd: item.subType === 'UNEMPLOYED' ? 'чел.' : null,
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo?.toString() || '-',
      }))

      const LIVESTOCK = DATA.LIVESTOCK?.map((item: any) => ({
        ...item,
        value: item.currentYearVal?.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        add: ['MEAT', 'COW_MILK'].includes(item.subType)
          ? 'тонн'
          : item.subType === 'CHICKEN_EGG'
            ? 'тыс. шт.'
            : '',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo?.toString() || '-',
      }))

      setData({ SER, BUDGET, MCB, EMPLOYMENT, LIVESTOCK })
    }

  }, [DATA, language])

  if (!data) return null

  return (
    data && <SERContainer>
      <div className="tabs">
        {tabs.map((tab: string) => (
          <div
            key={tab}
            className={selectedTab === tab ? 'tab active' : 'tab'}
            onClick={() => setSelectedTab(tab)}
          >
            {t('ser.' + tab)}
          </div>
        ))}
      </div>

      <SERTable>
        <table>
          {getTHead()}
          {getTBody()}
        </table>
      </SERTable>
    </SERContainer >
  )
}

export default SER
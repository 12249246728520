import styled from "styled-components";
import { BasicStatsItem } from "../index.styles";

export const ScreeningItem = styled.div<{ height?: string }>`
  padding: 0.625rem;
  background: var(--bg-color);
  border: 1px solid var(--borderGray);
  border-radius: 0.625rem;
  max-height: ${({ height }) => height};
  height: ${({ height }) => height};

  .body {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;

    &__item {
      display: flex;
      gap: 0.625rem;
      padding: 0.625rem 0;
      border-bottom: 1px solid var(--borderGray); 
      align-items: center;
      flex: 1;
      justify-content: space-between;
    }

    .increase {
      display: flex;
      gap: 0.625rem;
      align-items: baseline;

      > div:first-child {
        display: flex;
        gap: 0.3125rem;
        align-items: baseline; 
      }
    }

    .percent-value {
      min-width: 3.75rem;
      text-align: right;
    }

    .icon-container {
      display: flex;
      gap: 0.625rem;
      align-items: center;
    }

    .icon {
      width: 1.4375rem;
      height: 1.4375rem;
      background: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      svg,
      img {
        width: 1.4375rem;
        height: 1.4375rem;
      }
    }
  }

  .line-chart-container {
    flex: 1;
    max-height: 100%;
    overflow: hidden;
    max-width: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    
  }

  #legend>ul {
  display: flex;
  justify-content: center;
}

#legend li {
  cursor: pointer;
  margin: 0px 10px;
  display: flex;
}

#legend li span {
  padding-left: 8px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}

`

export const ScreeningColumn = styled.div<{ maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  gap: 1.75px;
  flex: 0.33;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  overflow: hidden;
`

export const Cards = styled.div`
  height: 42%;
  display: flex;
  gap: 0.3125rem;
  flex-direction: column;

  .card {
    padding: 0.5rem;
    background: var(--bg-color);
    border: 1px solid var(--borderGray);
    border-radius: 0.625rem;
  }

  .row {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
    &__header {
      display: flex;
      justify-content: space-between;
      text-align: center;
      white-space: nowrap;

      > div:first-child {
        text-align: left;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.3125rem;
      justify-items: right;

      .tal {
        justify-self: left;
      }

      .increase {
        display: flex;
        gap: 0.3125rem;
        align-items: baseline;
      }
    }
  }

  .screening-grid { 
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    gap: 3px;
    display: grid;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      align-items: center;
      justify-content: center;
    }

    .value {
      display: flex;
      gap: 0.25rem;
      align-items: baseline;
    }

    .percents {
      display: flex;
      justify-content: space-between;
    }
  }
`

export const ScreeningContainer = styled.div<{ isFull?: boolean }>`
  display: flex;
  flex-direction: column;

  .tabs {
    width: 100%;
    background-color: #f6f6f6;
    display: flex;
    padding: 0.625rem 1.25rem;
    align-items: center;
    height: 3.125rem;
    gap: 0.625rem;
    border-bottom: 1px solid #DBDCE0;

    .tab {
      padding: 0 1rem;
      background: #E2E2E2;
      border-radius: 0.3125rem;
      font-size: 0.875rem;
      color: var(--text-color);
      cursor: pointer;
      white-space: nowrap;
      height: 2rem;
      display: flex;
      align-items: center;      

      &.active {
        background: linear-gradient(90deg, #226AC3 0%, #04A958 100%);
        color: #fff;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    > div {
      max-width: ${({ isFull }) => isFull ? '100%' : '99%'}; 
    }

    &.c-2 {
      grid-template-columns: repeat(2, 1fr);
      gap: 1.25rem;
    }

    &.c-3 {
      grid-template-columns: repeat(3, 1fr);
      gap: 1.25rem;
      max-height: calc(100vh - 10.75rem);
    }

    &.equal-rows {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-auto-rows: 1fr; 

      > div {
        max-width: 100%; 
      }
    }
  }

  table.screening-table {
    border-collapse: collapse;
    margin-top: 0.625rem;
    margin-bottom: 1.25rem;
    
    td,
    th {
      height: 2.5rem;
      border-bottom: 1px solid #DBDCE0;
      border-right: 1px solid #DBDCE0;
      padding-left: 1rem;
    }

    tr {
      th, 
      td {
        &:last-child {
          border-right: none;
        }
      }
    }

    tr:last-child {
      th,
      td {
        border-bottom: none;
      }
    }

    thead th {
      text-align: left;
    }

    tbody {
      tr:nth-child(odd) {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .mcb-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;

    .value {
      display: flex;
      gap: 0.625rem;
      align-items: baseline;
    }
  }

  table.mcb-table {
    border-collapse: collapse;
    margin-top: 0;
    margin-bottom: 1.25rem;

    td,
    th {
      border-bottom: 1px solid #DBDCE0;
      padding-left: 1rem;
    }

    th {
      height: 3.125rem;
      text-align: left;
    }

    tbody {
      td {
        height: 4.25rem;
      }
    }
  }

  .farming {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
    padding: 1.25rem;
    max-height: calc(100vh - 10.75rem);
    overflow-y: auto;

    > div {
      max-width: 99%; 
      min-height: 16.0625rem;
    }
  }
`;

export const ColoredBasicItem = styled(BasicStatsItem) <{ type: 'red' | 'orange' | 'green' }>`
  .comments {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 0.625rem 0;

    .comment {
      padding: 0.3125rem 0.625rem;
      border-bottom: 1px solid #DBDCE0;
      flex: 1;
      display: flex;
      align-items: center;
      word-break: break-word; 
      white-space: normal; 

      &:nth-child(odd) {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }

  ${({ type }) => type === 'red' && `
    border-color: #C57979;
    .title {
      background: #FFECEC;
      border-color: #C57979;
    }

    .body {
      border-color: #C57979;
    }
  `}

  ${({ type }) => type === 'orange' && `
    border-color: #D8A602;
    .title {
      background: #FFF2CB;
      border-color: #D8A602;
    }

    .body {
      border-color: #D8A602;
    }
  `}

  ${({ type }) => type === 'green' && `
    .title {
      background: #ECFFD7;
    }
  `}
`
import React from 'react'
import { DistrictWrapper, } from './index.styles';

import { ISettlement } from '../../interfaces/snp.interface';
import { getLang } from '../../utils/helpers.utils';

interface IProps {
  district: ISettlement;
  onClick: (item: any) => void;
}

const DistrictItem: React.FC<IProps> = ({ district, onClick }) => {

  return (
    <DistrictWrapper isOpen={false} status={district.status}>
      <div className="district-name" onClick={() => onClick(district)} >
        <span>{district[`name${getLang()}`]}</span>
      </div>
    </DistrictWrapper>
  )
}

export default DistrictItem
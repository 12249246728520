import React, { FC, useEffect, useState } from 'react'
import { BasicStatsItem } from '../index.styles'
import { Text } from '../../text.component'
import { Bar, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { EmploymentCategories, IHumanResources, SocialCategories } from '../../../interfaces/snp.interface';
import { employmentOptions, populationByAgeOptions, populationBySocialStatusOptions, populationDynamicOptions } from './chart-options';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { splitChartLabels } from '../../../utils/helpers.utils';

interface IProps {
  data: IHumanResources
}

const keysRu = [['Получатели', 'АСП'], 'Пенсионеры', 'Многодетные', ['Люди с', 'огр.', 'возможностями']]
const keysKz = [['АӘК', 'алушылары'], 'Зейнеткерлер', 'Көпбалалы', ['Мүмкіндігі', 'шектеулі', 'адамдар']]

const HumanResourcesTab: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();
  const { language } = i18n

  const [populationDynamic, setPopulationDynamic] = useState<any>(null);
  const [populationByAge, setPopulationByAge] = useState<any>(null);
  const [populationBySocialStatus, setPopulationBySocialStatus] = useState<any>(null);
  const [employment, setEmployment] = useState<any>(null);

  const employmentKeys = splitChartLabels(['hired','selfEmployed', 'unemployed', 'workingElderly'].map((item) => t(`screening-page.${item}`))) 
  const socialStatusKeys = splitChartLabels(['TSArecipients', 'pensioners', 'largeFamilies', 'disabled'].map((item) => t(`screening-page.${item}`)))

  const buildGraphs = () => {
    if (data) {
      setPopulationDynamic(
        {
          labels: data.populationDynamic?.map((item: any) => item.year),
          datasets: [
            {
              label: t('form.population'),
              data: data.populationDynamic?.map((item: any) => item.population),
              borderColor: '#6968FD',
              backgroundColor: '#6968FD',
              pointRadius: 4,
              pointBackgroundColor: '#6968FD',
            }
          ]
        }
      );

      setPopulationByAge({
        labels: data.populationByAge?.map((item: any) => item.age),
        datasets: [
          {
            label: t('form.population'),
            data: data.populationByAge?.map((item: any) => item.population),
            backgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 76,
          }
        ]
      });

      setPopulationBySocialStatus({
        labels: socialStatusKeys,
        datasets: [
          {
            label: t('screening-page.TSArecipients'),
            data: [data.populationBySocialStatus?.[SocialCategories.TSArecipients], null, null, null],
            backgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: t('screening-page.pensioners'),
            data: [null, data.populationBySocialStatus?.[SocialCategories.pensioners], null, null],
            backgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: t('screening-page.largeFamilies'),
            data: [null, null, data.populationBySocialStatus?.[SocialCategories.largeFamilies], null],
            backgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: t('screening-page.firstGroupDisabled'),
            data: [null, null, null, data.populationBySocialStatus?.[SocialCategories.firstGroupDisabled]],
            backgroundColor: '#5BC7D9',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: t('screening-page.secondGroupDisabled'),
            data: [null, null, null, data.populationBySocialStatus?.[SocialCategories.secondGroupDisabled]],
            backgroundColor: '#92D050',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: t('screening-page.thirdGroupDisabled'),
            data: [null, null, null, data.populationBySocialStatus?.[SocialCategories.thirdGroupDisabled]],
            backgroundColor: '#2F6DBD',
            borderRadius: 5,
            barThickness: 76,
          },
        ]
      });


      setEmployment({
        labels: employmentKeys,
        datasets: [
          {
            label: t('screening-page.budget'),
            data: [data.employment?.[EmploymentCategories.hired_budget], data.employment?.[EmploymentCategories.selfEmployed], data.employment?.[EmploymentCategories.unemployed], data.employment?.[EmploymentCategories.workingElderly]],
            backgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: t('screening-page.private'),
            data: [data.employment?.[EmploymentCategories.hired_private], null, null, null],
            backgroundColor: '#92D050',
            borderRadius: 5,
            barThickness: 76,
          },
        ]
      })
    }
  }

  useEffect(() => {
    buildGraphs()
  }, [data, language])

  return (
    <div className='grid c-2 screening human-resources'>
      {
        data.populationDynamic && populationDynamic && <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t('screening-page.population-dynamic')}</Text>
            </div>
          </div>
          <div className="body">
            <Line data={populationDynamic} options={populationDynamicOptions} plugins={[ChartDataLabels]} />
          </div>
        </BasicStatsItem>
      }

      {data.populationByAge && populationByAge && <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t('screening-page.population-by-age')}</Text>
          </div>
        </div>
        <div className="body">
          <Bar data={populationByAge} options={populationByAgeOptions} plugins={[ChartDataLabels]} />
        </div>
      </BasicStatsItem>}

      {data.populationBySocialStatus && populationBySocialStatus && <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t('screening-page.population-by-social-status')}</Text>
          </div>
        </div>
        <div className="body">
          <Bar data={populationBySocialStatus} options={populationBySocialStatusOptions} plugins={[ChartDataLabels]} />
        </div>
      </BasicStatsItem>}

      {data.employment && employment && <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t('screening-page.employment')}</Text>
          </div>
        </div>
        <div className="body">
          <Bar data={employment} options={employmentOptions} plugins={[ChartDataLabels]} />
        </div>
      </BasicStatsItem>}
    </div>
  )
}

export default HumanResourcesTab
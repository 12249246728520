import React, { FC, useEffect, useRef, useState } from 'react'
import { IInvestmentProject } from '../../../interfaces/snp.interface';
import { useTranslation } from 'react-i18next';
import { Text } from '../../text.component';
import { BasicStatsItem, FullScreenTable } from '../index.styles';
import { ArrowDown, SortIcon } from '../../../assets/icons';
import { getLang } from '../../../utils/helpers.utils';

interface IProps {
  data: IInvestmentProject[];
  scrollToTop: () => void;
}

const keys: string[] = ['name', 'city', 'realizationDate', 'totalPrice', 'rb', 'currentYearTotalSum', 'faktsumCurrYear', 'percentTekGod', 'developmentShare']

const InvestProjectsFullScreen: FC<IProps> = ({ data, scrollToTop }) => {
  const { t } = useTranslation();
  const tableRef = useRef<HTMLDivElement>(null);

  const [sorted, setSorted] = useState<IInvestmentProject[]>(data.sort((a: IInvestmentProject, b: IInvestmentProject) => b.developmentShare - a.developmentShare));
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';

    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedData = [...data].sort((a: IInvestmentProject, b: IInvestmentProject) => {
      if (key === 'name') {
        return direction === 'asc'
          ? a[`name${getLang()}`].localeCompare(b[`name${getLang()}`])
          : b[`name${getLang()}`].localeCompare(a[`name${getLang()}`]);
      } else if (key === 'city') {
        return direction === 'asc'
          ? a.city.localeCompare(b.city)
          : b.city.localeCompare(a.city);
      }

      const valueA = +(a[key as keyof IInvestmentProject] || 0);
      const valueB = +(b[key as keyof IInvestmentProject] || 0);
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    });

    setSorted(sortedData);
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    setSorted(data.sort((a: IInvestmentProject, b: IInvestmentProject) => b.developmentShare - a.developmentShare));
  }, [data]);

  return (sorted && <BasicStatsItem ref={tableRef}>
    <div className="body" style={{ padding: 0 }}>
      <FullScreenTable >
        <thead>
          <tr>
            <td style={{ borderTopLeftRadius: "0.625rem" }}>№</td>
            {keys.map((key: string, index: number) => (
              <td key={index}>
                <div className="td-flex">
                  <Text fontSize='0.875rem' color='var(--text-color)'  >{t(`budgetInvestmentProjectStats.${key}`)}</Text>
                  <SortIcon height={10} onClick={() => handleSort(key)} />
                </div>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {sorted.map((item: IInvestmentProject, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              {keys.map((key: string, index: number) => (
                <td key={index}>
                  <Text className={`subType ${key}`} fontSize='0.875rem' color='var(--text-color)' fontWeight={400}>
                    {key === 'name'
                      ? item[`name${getLang()}` as keyof IInvestmentProject]
                      : item[key as keyof IInvestmentProject] ?? '0'}
                  </Text>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <div onClick={scrollToTop} className='go-up'><ArrowDown /></div>

      </FullScreenTable>

    </div>
  </BasicStatsItem>
  )
}

export default InvestProjectsFullScreen
import React, { FC } from 'react'
import { ColoredBasicItem } from '../tabs/index.styles'
import { Text } from '../../text.component'
import { ISWOT } from '../../../interfaces/snp.interface'
import { swotOrder } from '../../../pages/admin/swot.page'
import { useTranslation } from 'react-i18next'
import { getLang } from '../../../utils/helpers.utils'

interface StyleItem {
  titleColor: string;
  type: 'red' | 'orange' | 'green';
}

const swotStyles: { [type: string]: StyleItem } = {
  strengths: {
    titleColor: '#118F84',
    type: 'green',
  },
  weaknesses: {
    titleColor: '#C57979',
    type: 'red',
  },
  opportunities: {
    titleColor: '#D8A602',
    type: 'orange',
  },
  threats: {
    titleColor: '#C57979',
    type: 'red',
  }
}

interface IProps {
  data: ISWOT;
}

const SwotTab: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className='grid c-2 screening equal-rows'>
      {
        data && swotOrder.map((key: any) => {
          const item = swotStyles[key];

          return <ColoredBasicItem key={key} type={item.type}>
            <div className="title">
              <div className="left">
                <Text color={item.titleColor} fontWeight={500} fontSize='0.875rem'>{key.toUpperCase()}</Text>
                <Text color={'#202224'} fontWeight={400} fontSize='0.75rem'>{t('screening-page.swot-analysis.' + key)}</Text>
              </div>
            </div>
            <div className="body">
              {data[key as keyof ISWOT] && <div className="comments">
                {
                  data[key as keyof ISWOT]?.map((item) => (
                    <div key={item[`text${getLang()}`] + Math.random()} className='comment'>
                      <Text fontSize='0.75rem' fontWeight={400} color='#000105'>{item[`text${getLang()}`]}</Text>
                    </div>
                  ))
                }
              </div>}
            </div>
          </ColoredBasicItem>
        })
      }
    </div>
  )
}

export default SwotTab
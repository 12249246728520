import axios from "axios";
import { IScreeningInfo } from "../interfaces/snp.interface";
import { api } from "../pages";

export type screeningKeys = 'humanResources' | 'agriculture' | 'conclusion' | 'landFund' | 'smbDevelopment' | 'swotAnalysis'

export const getScreening = (kato: number, year: number = 2025): Promise<IScreeningInfo> => {
  return axios.get(`/api/public/screening?kato=${kato}&year=${year}`).then(res => res.data.data);
}

export const updateScreening = (kato: number, key: screeningKeys, data: any, year: number = 2025) => {
  return api.post(`/supervisor/screening/update?kato=${kato}&key=${key}&year=${year}`, data).then(res => res.data);
}
import React, { useEffect, useState } from 'react'
import LeftMenu, { IListOption } from '../../components/left-menu/left-menu.component'
import RightMenu from '../../components/right-menu/right-menu.component'
import styled from 'styled-components'
import { cityObject, OBLAST_KATO } from '../../constants/snp.constant'
import { useNavigate, useParams } from 'react-router-dom';
import { APIProvider } from '@vis.gl/react-google-maps';

import { IMarker, IRegion } from '../../interfaces/snp.interface'
import GoogleMapComponent from "../../components/google-map/google-map.component";
import { useKeycloak } from "@react-keycloak/web";
import { getUserInfo } from '../../requests/auth.request'
import { getFilteredTree } from '../../requests/supervisor.request'

const HomeWrapper = styled.div`
  display: flex;
  height: 100vh;
  gap: 5rem;
  overflow: hidden;
  
  .absolute {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
  };
`
const Home = () => {
  const { kato } = useParams();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const [selectedLink, setSelectedLink] = useState<string | null>(null);
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<IRegion>();
  const [selectedMarker, setSelectedMarker] = useState<IMarker | null>(null);
  const [showLeftMenu, setShowLeftMenu] = useState<boolean>(true)

  const toggleShowLeftMenu = () => {
    setShowLeftMenu(!showLeftMenu);
  }

  useEffect(() => {
    localStorage.setItem('lng', 'ru');
  }, []);

  useEffect(() => {
    getUserInfo().then((res) => {
      if (kato && !res.kato_access.includes(+kato) && !res.kato_access.includes(0)) {
        !!res.kato_access.length ? navigate(`/${res.kato_access[0]}`) : navigate(`/${OBLAST_KATO}`);
      } else {
        if (kato && +kato === OBLAST_KATO) {
          setSelectedRegion({ kato: OBLAST_KATO, nameRu: 'Актюбинская область', nameKz: 'Ақтөбе облысы', coordinates: [0, 0] });
        } else {
          getFilteredTree().then((res: { list: IListOption[] }) => {
            const list = res.list;
            if (kato) {
              if (list.map(item => item.kato).includes(+kato)) {
                const foundRegion = list.find(item => item.kato === +kato);
                setSelectedRegion({ ...foundRegion, coordinates: [0, 0] } as IRegion);
                +kato !== +cityObject.kato && setSelectedMarker(null);
              } else {
                const foundRegionByMarker = list.find(item => item.kato.toString().slice(0, 4) === kato.slice(0, 4));
                if (foundRegionByMarker) {
                  setSelectedRegion({ ...foundRegionByMarker, coordinates: [0, 0] } as IRegion);
                  const foundChild = foundRegionByMarker.children.find((item: any) => item.kato === +kato);
                  if (foundChild) {
                    setSelectedMarker({
                      code: foundChild.kato,
                      nameRu: foundChild.nameRu,
                      nameKz: foundChild.nameKz,
                      latitude: '0',
                      longitude: '0',
                    });
                  }

                }
              }
            } else {
              setSelectedRegion({ kato: OBLAST_KATO, nameRu: 'Актюбинская область', nameKz: 'Ақтөбе облысы', coordinates: [0, 0] });
            }
          })
        }
      }
    })
  }, [kato]);

  useEffect(() => {
    if (!kato) {
      getUserInfo().then((res) => {
        const kato = res.kato_access.includes(0) ? OBLAST_KATO : res.kato_access[0];
        navigate(`/${kato}`);
        +kato === OBLAST_KATO && setSelectedRegion({ kato: OBLAST_KATO, nameRu: 'Актюбинская область', nameKz: 'Ақтөбе облысы', coordinates: [0, 0] });
      })
    }
  }, []);

  return (
    <APIProvider apiKey={'AIzaSyBY8p3nROyRu1bh9hExu8uQXKsa_F_mIjI'} language={'ru'} libraries={['places']}>
      <HomeWrapper>
        <LeftMenu
          selectedLink={selectedLink}
          setSelectedLink={setSelectedLink}
          selectedRegion={selectedRegion!}
          setSelectedRegion={setSelectedRegion}
          selectedMarker={selectedMarker!}
          setSelectedMarker={setSelectedMarker}
          markers={markers}
          keycloak={keycloak}
          showLeftMenu={showLeftMenu}
        />
        <RightMenu
          selectedLink={selectedLink}
          setSelectedLink={setSelectedLink}
          selectedMarker={selectedMarker}
          selectedRegion={selectedRegion}
          toggleShowLeftMenu={toggleShowLeftMenu}
          showLeftMenu={showLeftMenu}
          setShowLeftMenu={setShowLeftMenu}
        />

        <GoogleMapComponent
          setSelectedRegion={setSelectedRegion}
          setSelectedMarker={setSelectedMarker}
        />

      </HomeWrapper>
    </APIProvider>
  )
}

export default Home
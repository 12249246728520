import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { HeaderContainer, Item, TabButton } from './index.styles';
import { headerOptions } from '../../constants/navlinks.constant';
import { ArrowRight, DeleteX } from '../../assets/icons';
import { useParams } from 'react-router-dom';
import { OBLAST_KATO } from '../../constants/snp.constant';

interface IProps {
  selectedTab: string | null;
  setSelectedTab: (tab: string) => void;
  onClose: () => void;
  toggleShowLeftMenu: () => void;
  showLeftMenu: boolean;
}

const Header: React.FC<IProps> = ({ selectedTab, setSelectedTab, onClose, toggleShowLeftMenu, showLeftMenu }) => {
  const { t } = useTranslation();
  const { kato } = useParams();

  const [filteredOptions, setFilteredOptions] = useState<string[]>([])

  useEffect(() => {
    if (kato && +kato === OBLAST_KATO) {
      setFilteredOptions(headerOptions.filter(option => option !== 'analytics'))
      setSelectedTab('snp')
    } else {
      setFilteredOptions(headerOptions)
    }
  }, [kato])

  return (
    <HeaderContainer>
      <div className="toggle" onClick={toggleShowLeftMenu}> <ArrowRight width={10} fill='var(--primary-color)' style={{ transform: showLeftMenu ? 'rotate(180deg)' : 'rotate(0deg)' }} /> </div>
      <Item full>
        {filteredOptions.map((option) => (
          <TabButton
            key={option}
            selected={selectedTab === option}
            onClick={() => setSelectedTab(option)}>
            {t(option)}
          </TabButton>
        ))}
      </Item>

      <Item onClick={onClose} className='close-button'>
        <DeleteX />
      </Item>
    </HeaderContainer >
  )
}

export default Header
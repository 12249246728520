import Keycloak from "keycloak-js";

const location = window.location;

const realm = location.hostname.includes('localhost') || location.hostname.includes('abat') ? 'digpasstest' : "aktobe-sic"
const clientId = location.hostname.includes('localhost') || location.hostname.includes('abat') ? 'aktobe-ts' : 'aktobe-ea'

const keycloak = new Keycloak({
    url: "https://vpn.csi.kz/auth",
    realm,
    clientId,
});

export default keycloak;
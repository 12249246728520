import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchIcon from '../../assets/icons/search.svg'
import XIcon from '../../assets/icons/x.svg'

import { InputIcons, OptionsList, SearchInputWrapper } from './index.styles'
import { getLang } from '../../utils/helpers.utils';
import { IListOption } from './left-menu.component';
import { getUserInfo } from '../../requests/auth.request';
import { cityObject, OBLAST_KATO } from '../../constants/snp.constant';

interface IProps {
  searchValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  options: IListOption[];
  onSelectOption: (option: IListOption) => void;
}

export const aktobeOblast = { kato: OBLAST_KATO, nameRu: 'Актюбинская область', nameKz: 'Ақтөбе облысы', children: [] }

const Search: React.FC<IProps> = ({ searchValue, onChange, onClear, options, onSelectOption }) => {
  const { t } = useTranslation();
  const listRef = useRef<HTMLInputElement | null>(null);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);

  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  const handleOptionClick = (option: any) => {
    onSelectOption(option);
    setShowOptions(false);
  };

  const handleClear = () => {
    onClear();
    setShowOptions(false);
  }

  useEffect(() => {
    const filtered = options.filter(option =>
      option[`name${getLang()}`].toLowerCase().includes(searchValue.toLowerCase()) ||
      option.children.some(child =>
        child[`name${getLang()}`].toLowerCase().includes(searchValue.toLowerCase())
      )
    );
    setFilteredOptions(filtered.sort((a, b) => a.kato - b.kato));
  }, [options, searchValue]);


  useEffect(() => {
    searchValue.length > 0 && setShowOptions(true);
  }, [searchValue])

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (listRef.current && !listRef.current.contains(e.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    getUserInfo().then((res) => {
      setKatoAccessList(res.kato_access);
    })
  }, []);

  return (
    <SearchInputWrapper ref={listRef}>
      <input
        type="text"
        placeholder={t('chooseSnp')}
        onChange={onChange}
        value={searchValue}
        onFocus={() => setShowOptions(true)}
      />
      <InputIcons>
        <img src={SearchIcon} alt="search icon" />
        <span className="divider"></span>
        <img src={XIcon} alt="clear icon" onClick={handleClear} />
      </InputIcons>
      {!!katoAccessList.length && showOptions && filteredOptions && (
        <OptionsList>
          {
            katoAccessList.includes(0) && <div className="item" onClick={() => handleOptionClick(aktobeOblast)}>
              {aktobeOblast[`name${getLang()}`]}
            </div>
          }
          {
            (katoAccessList.includes(0) || katoAccessList.includes(+cityObject.kato)) && <div className="item" onClick={() => handleOptionClick(cityObject)}>
              {cityObject[`name${getLang()}`]}
            </div>
          }
          {filteredOptions.map((option, index) => (
            <>
              {(katoAccessList.includes(0) || katoAccessList.includes(+option.kato)) && <div className={`item ${option.fillingStatus !== 'NOT_STARTED' ? '' : ''}`} key={index} onClick={() => handleOptionClick(option)}>
                {option[`name${getLang()}`].trim()}
              </div>}
              <div>
                {option.children && option.children.length > 0 && option.children.map((child: any, index: any) => (
                  <div key={index} className={`item child ${['COMPLETED', 'IN_PROCCESS'].includes(child.fillingStatus) ? '' : ''}`} onClick={() => handleOptionClick(child)}>
                    {child[`name${getLang()}`]}
                  </div>
                ))}
              </div>
            </>
          ))}
        </OptionsList>
      )
      }
    </SearchInputWrapper >
  );
};

export default Search
import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { IPeople } from '../../interfaces/snp.interface';
import PhotoPlaceholder from '../../assets/images/photo-placeholder.png';
import { PassportContainer, Item, Photo, PersonInfo } from './index.styles'
import ZoomableImageModal from '../right-menu/zoomable-image.component';
import { capitalize, formatDate, getLang, keepCapitalized } from '../../utils/helpers.utils';
import { Phone } from '../../assets/icons';
import { getImageUrl } from '../admin-page/general-info.component';
import styled from 'styled-components';
import { Text } from '../text.component';

interface IProps {
  people: IPeople,
}

const Person = styled(Item)`
  gap: 1.25rem;
`

const Passport: React.FC<IProps> = memo(({ people }) => {
  const { t } = useTranslation()

  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openImageModal = (imageId: number) => {
    setIsOpen(true);
    setSelectedImage(imageId);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  }

  return (
    <PassportContainer>
      {people && people.length > 0 && people.map((person, index) =>
        <React.Fragment key={`${person.nameRu}_${index}_${Math.random()}`}>
          <Person>
            <Photo
              src={(person.imageId && getImageUrl(+person.imageId)) || PhotoPlaceholder}
              alt='profile photo' onClick={() => person.imageId && openImageModal(+person.imageId)}
            />
            <PersonInfo>
              <div className='group'>
                <Text fontSize='0.875rem' fontWeight={500} color="var(--primary-color)">{capitalize(person[`name${getLang()}`] as string)?.split(' ')[0]}</Text>
                <Text fontSize='0.875rem' fontWeight={500} color="var(--primary-color)">{capitalize(person[`name${getLang()}`] as string)?.split(' ').slice(1).join(" ")}</Text>
                <Text fontSize='0.875rem' fontWeight={400} color="#717579">{keepCapitalized(person[`position${getLang()}`] as string)}</Text>
              </div>

              {
                !(person.type === 'p' && !person[`name${getLang()}`]) && <>
                  <div className='group'>
                    <div className='position'>
                      <Text fontSize='0.875rem' fontWeight={400} color="#717579">{t('people.worksFrom')}:</Text>
                      <Text fontSize='0.875rem' fontWeight={500} color="var(--primary-color)">{person.inPositionFrom && formatDate(person.inPositionFrom)}</Text>
                    </div>

                    <div className="position no-border">
                      <Phone />

                      <a className='phone-number' href={`tel:${person.phone}`}> <Text fontSize='0.875rem' fontWeight={500} color="var(--primary-color)"> {person.phone}</Text></a>
                    </div>
                  </div>
                </>
              }

            </PersonInfo>
          </Person>
          {index !== people.length - 1 && <div className='divider'></div>}
        </React.Fragment>
      )}

      {selectedImage && (
        <ZoomableImageModal
          isFileUpload={true}
          isOpen={isOpen}
          onClose={closeImageModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          currentImageList={[]}
        />
      )}

    </PassportContainer>
  )
});

export default Passport
import React, { useEffect, useState } from 'react'
import { tabs } from '../../components/right-menu/tabs/screening.component'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import HumanResourcesPage from './human-resources.page';
import { EmploymentCategories, IHumanResources, ILandFund, IMCB, IScreeningFarming, IScreeningInfo, SocialCategories } from '../../interfaces/snp.interface';
import LandFundPage from './land-fund.page';
import { useNavigate, useParams } from 'react-router-dom';
import MCBPage from './mcb.page';
import { getScreening } from '../../requests/screening.request';
import SWOTPage from './swot.page';
import AgriculturePage from './agriculture.page';
import { currentYear } from '../../constants/snp.constant';
import { useSharedContext } from '../../contexts/shared.context';

const Tabs = styled.div`
  display: flex;
  gap: 0.9375rem;
  width: 100%;
  padding: 0.3125rem 0.625rem;
  align-items: center;
  border-radius: 0.3125rem;
  background: #F2EFDE;
  max-width: 100%;
  overflow-x: auto;
  height: 2rem;
  margin-bottom: 0.9375rem;

  ::-webkit-scrollbar {
    height: 2px; 
    margin-bottom: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin-bottom: 0.625rem; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #98A3A9; 
    border-radius: 5px;
    border-left: 15px solid transparent; 
    border-right: 35px solid transparent;
    background-clip: padding-box;
  }
`;

const Tab = styled.div<{ selected?: boolean }>`
  color: ${({ selected }) => selected ? '#202224' : '#606367'};
  font-weight: ${({ selected }) => selected ? ' 700' : '400'};
  border-bottom: ${({ selected }) => selected ? ' 1px solid #202224' : 'none'};
  font-size: 0.75rem;
  cursor: pointer;  
  white-space: nowrap;
`;

const ScreeningPage = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { kato, tab } = useParams();
  const navigate = useNavigate();
  const { selectedDataYear } = useSharedContext();

  const [screeningData, setScreeningData] = useState<IScreeningInfo>({
    humanResources: null,
    landFund: null,
    smbDevelopment: null,
    swotAnalysis: null,
    agriculture: null
  });

  const handleSelectTab = (tab: string) => {
    setSelectedTab(tab);
    navigate(`/admin/${kato}/screening/${tab}`);
  }

  const loadHumanResources = (): IHumanResources => {
    return {
      populationDynamic: [...Array.from({ length: 7 }, (_, i) => ({
        year: +selectedDataYear - (6 - i),
        population: 0,
      }))],
      populationByAge: ['0-5', '6-17', '18-29', '30-62', '63 и старше'].map((age: any) => {
        return {
          age,
          population: 0,
        }
      }),
      populationBySocialStatus: {
        [SocialCategories.TSArecipients]: 0,
        [SocialCategories.pensioners]: 0,
        [SocialCategories.largeFamilies]: 0,
        [SocialCategories.firstGroupDisabled]: 0,
        [SocialCategories.secondGroupDisabled]: 0,
        [SocialCategories.thirdGroupDisabled]: 0,
      },
      employment: {
        [EmploymentCategories.hired_budget]: 0,
        [EmploymentCategories.hired_private]: 0,
        [EmploymentCategories.selfEmployed]: 0,
        [EmploymentCategories.unemployed]: 0,
        [EmploymentCategories.workingElderly]: 0,
      }
    }
  }

  const loadLandFund = (): ILandFund => {
    return {
      largeLandowners: [],
      landFundItems: {
        settlementLand: '',
        agricultural_farmEnterprises: '',
        agricultural_arable: '',
        agricultural_pasture: '',
        agricultural_total: '',
        reserve_arable: '',
        reserve_pasture: '',
        reserve_total: '',
        otherLand: '',
      }
    }
  }

  const loadMcb = (): IMCB => {
    return {
      mcbSubjects: {
        total: '',
        TOO: '',
        IP: '',
        KH: '',
        SPK: '',
      },
      registeredSubjects: {
        juridical: '',
        agricultural: '',
        construction: '',
        retail: '',
        printing: '',
        finance: '',
        commerce: '',
      },
      totalSum: '',
      creditSupport: {
        subsidizing_projects: '',
        subsidizing_sum: '',
        guarantee_projects: '',
        guarantee_sum: '',
        grant_projects: '',
        grant_sum: '',
      }
    }
  }

  const loadAgriculture = (): IScreeningFarming => {
    return {
      animalHusbandry: {
        KPC: {
          kh: '',
          lph: '',
        },
        MPC: {
          kh: '',
          lph: '',
        },
        horses: {
          kh: '',
          lph: '',
        },
        camels: {
          kh: '',
          lph: '',
        },
      },
      agriculturalGrowth: {
        KPC: Array.from({ length: 3 }, (_, i) => ({ year: selectedDataYear - 3 + i, value: '' })),
        MPC: Array.from({ length: 3 }, (_, i) => ({ year: selectedDataYear - 3 + i, value: '' })),
        horses: Array.from({ length: 3 }, (_, i) => ({ year: selectedDataYear - 3 + i, value: '' })),
        birds: Array.from({ length: 3 }, (_, i) => ({ year: selectedDataYear - 3 + i, value: '' })),
        camels: Array.from({ length: 3 }, (_, i) => ({ year: selectedDataYear - 3 + i, value: '' })),
      },
      selfSufficiency: {
        sheep: '',
        bird: '',
        egg: '',
        cucumber: '',
        tomato: '',
      },
      poultry: {
        goose: {
          kh: '',
          lph: '',
        },
        duck: {
          kh: '',
          lph: '',
        },
        chicken: {
          kh: '',
          lph: '',
        },
      },
      meat: {
        KPC: {
          kh: '',
          lph: '',
        },
        MPC: {
          kh: '',
          lph: '',
        },
        horses: {
          kh: '',
          lph: '',
        },
        camels: {
          kh: '',
          lph: '',
        },
      },
      milk: {
        dairy: '',
        milkProduction: '',
      },
      cropArea: {
        kh: {
          carnival: '',
          forage: '',
          grains: '',
          potato: '',
          vegetables: '',
        },
        lph: {
          carnival: '',
          forage: '',
          grains: '',
          potato: '',
          vegetables: '',
        }
      },
      yearlyMeat: {
        KPC: {
          kh: '',
          lph: '',
        },
        MPC: {
          kh: '',
          lph: '',
        },
        horses: {
          kh: '',
          lph: '',
        },
        camels: {
          kh: '',
          lph: '',
        },
      },
      milkProduction: {
        butter: {
          yearly: '',
          average: '',
        },
        cottage: {
          yearly: '',
          average: '',
        },
        sourCream: {
          yearly: '',
          average: '',
        },
        kefir: {
          yearly: '',
          average: '',
        },
        milk: {
          yearly: '',
          average: '',
        },
      },
      harvest: {
        kh: {
          carnival: '',
          forage: '',
          grains: '',
          potato: '',
          vegetables: '',
        },
        lph: {
          carnival: '',
          forage: '',
          grains: '',
          potato: '',
          vegetables: '',
        }
      },
    }
  }

  const loadSwot = () => {
    return {
      strengths: [],
      weaknesses: [],
      opportunities: [],
      threats: [],
    }
  }

  const loadForm = () => {
    if (kato) {
      getScreening(+kato, selectedDataYear)
        .then(data => {
          let humanResources = data.humanResources;
          let landFund = data.landFund;
          let smbDevelopment = data.smbDevelopment;
          let swotAnalysis = data.swotAnalysis;
          let agriculture = data.agriculture;

          if (!data.humanResources) {
            humanResources = loadHumanResources();
          }

          if (!data.landFund) {
            landFund = loadLandFund();
          }

          if (!data.smbDevelopment) {
            smbDevelopment = loadMcb();
          }

          if (!data.swotAnalysis) {
            swotAnalysis = loadSwot();
          }

          if (!data.agriculture) {
            agriculture = loadAgriculture();
          }

          setScreeningData({
            humanResources,
            landFund,
            smbDevelopment,
            swotAnalysis,
            agriculture,
          })
        })
    }
  }

  useEffect(() => {
    if (kato) {
      loadForm();
      if (tab) {
        setSelectedTab(tab);
      } else {
        navigate(`/admin/${kato}/screening/${tabs[0]}`);
      }
    }
  }, [kato, tab, selectedDataYear]);

  return (
    <div>
      <Tabs>
        {tabs.map((tab) => (
          <Tab key={tab} onClick={() => handleSelectTab(tab)} selected={selectedTab === tab}>
            {t('screening-page.' + tab)}
          </Tab>
        ))}
      </Tabs>

      {screeningData && screeningData.humanResources && selectedTab === tabs[0] && <HumanResourcesPage updateForm={loadForm} data={screeningData.humanResources} />}
      {screeningData && screeningData.landFund && selectedTab === tabs[1] && <LandFundPage updateForm={loadForm} data={screeningData.landFund} />}
      {screeningData && screeningData.agriculture && selectedTab === tabs[2] && <AgriculturePage updateForm={loadForm} data={screeningData.agriculture} />}
      {screeningData && screeningData.smbDevelopment && selectedTab === tabs[3] && <MCBPage updateForm={loadForm} data={screeningData.smbDevelopment} />}
      {screeningData && screeningData.swotAnalysis && selectedTab === tabs[4] && <SWOTPage updateForm={loadForm} data={screeningData.swotAnalysis} />}

    </div>
  )
}

export default ScreeningPage
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { GoUp, Save } from '../../assets/icons';
import { checkVal, getCropTotal, getKHLPHTotal, getLang, handleGoUp, isRegion, t, updateKatoAccessList } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { IFarmingAreaItem, IFarmingItem, IFarmingItemsList, IScreeningFarming } from '../../interfaces/snp.interface';
import { updateScreening } from '../../requests/screening.request';
import { OBLAST_KATO } from '../../constants/snp.constant';
import { useSharedContext } from '../../contexts/shared.context';

interface IProps {
  data: IScreeningFarming;
  updateForm: () => void;
}

const keysOfAgriculturalGrowth: (keyof IScreeningFarming['agriculturalGrowth'])[] = ['KPC', 'MPC', 'horses', 'camels', 'birds'];
const keysOfSelfSufficiency: (keyof IScreeningFarming['selfSufficiency'])[] = ['sheep', 'bird', 'egg', 'cucumber', 'tomato'];
const keysOfCropArea: (keyof IScreeningFarming['cropArea'])[] = ['kh', 'lph'];
const keysOfCropAreaItem: (keyof IFarmingAreaItem)[] = ['vegetables', 'grains', 'potato', 'carnival', 'forage'];

const keys = {
  animalHusbandry: ['KPC', 'MPC', 'horses', 'camels'],
  poultry: ['goose', 'duck', 'chicken'],
  meat: ['KPC', 'MPC', 'horses', 'camels'],
  yearlyMeat: ['KPC', 'MPC', 'horses', 'camels'],
}

const AgriculturePage
  : FC<IProps> = ({ data, updateForm }) => {
    const { i18n: { language } } = useTranslation();
    const navigate = useNavigate();
    const { kato } = useParams();
    const { selectedDataYear } = useSharedContext();

    const formikRef = useRef<any>(null);
    const wrapperRef = useRef<any>(null);

    const [tree, setTree] = useState<any[]>([]);
    const [region, setRegion] = useState<number>(0);
    const [snp, setSnp] = useState<number>(0);
    const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
    const [isKatoRegion, setIsKatoRegion] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [agricultureData, setAgricultureData] = useState<IScreeningFarming>();

    const validateForm = (data: any) => {
      if (!agricultureData) return;
      setErrors({});
      let res = true;

      const animalHusbandry = agricultureData.animalHusbandry;
      const animalHusbandryKeys: (keyof IScreeningFarming['animalHusbandry'])[] = Object.keys(animalHusbandry) as (keyof IScreeningFarming['animalHusbandry'])[];
      if (animalHusbandryKeys.some(key => (checkVal(animalHusbandry[key].kh && animalHusbandry[key].kh !== '') || checkVal(animalHusbandry[key].lph && animalHusbandry[key].lph !== '')))) {
        for (const key of animalHusbandryKeys) {
          if (!checkVal(animalHusbandry[key].kh) || !checkVal(animalHusbandry[key].lph)) {
            setErrors((prev: any) => ({ ...prev, [`animalHusbandry-${key}`]: true }));
            toast.error(t(`errors.streetName`, language));
            return false;
          }
        }
      }

      const poultry = agricultureData.poultry;
      const poultryKeys: (keyof IScreeningFarming['poultry'])[] = Object.keys(poultry) as (keyof IScreeningFarming['poultry'])[];

      if (poultryKeys.some(key => (checkVal(poultry[key].kh) && poultry[key].kh !== '') || (checkVal(poultry[key].lph) && poultry[key].lph !== ''))) {
        for (const key of poultryKeys) {
          if (!checkVal(poultry[key].kh) || !checkVal(poultry[key].lph)) {
            setErrors((prev: any) => ({ ...prev, [`poultry-${key}`]: true }));
            toast.error(t(`errors.streetName`, language));
            return false;
          }
        }
      }

      const meat = agricultureData.meat;
      const meatKeys: (keyof IScreeningFarming['meat'])[] = Object.keys(meat) as (keyof IScreeningFarming['meat'])[];

      if (meatKeys.some(key => (checkVal(meat[key].kh) && meat[key].kh !== '') || (checkVal(meat[key].lph) && meat[key].lph !== ''))) {
        for (const key of meatKeys) {
          if (!checkVal(meat[key].kh) || !checkVal(meat[key].lph)) {
            setErrors((prev: any) => ({ ...prev, [`meat-${key}`]: true }));
            toast.error(t(`errors.streetName`, language));
            return false;
          }
        }
      }

      const yearlyMeat = agricultureData.yearlyMeat;
      const yearlyMeatKeys: (keyof IScreeningFarming['yearlyMeat'])[] = Object.keys(yearlyMeat) as (keyof IScreeningFarming['yearlyMeat'])[];

      if (yearlyMeatKeys.some(key => (checkVal(yearlyMeat[key].kh) && yearlyMeat[key].kh !== '') || (checkVal(yearlyMeat[key].lph) && yearlyMeat[key].lph !== ''))) {
        for (const key of yearlyMeatKeys) {
          if (!checkVal(yearlyMeat[key].kh) || !checkVal(yearlyMeat[key].lph)) {
            setErrors((prev: any) => ({ ...prev, [`yearlyMeat-${key}`]: true }));
            toast.error(t(`errors.streetName`, language));
            return false;
          }
        }
      }

      const agriculturalGrowth = agricultureData.agriculturalGrowth;
      const agriculturalGrowthKeys: (keyof IScreeningFarming['agriculturalGrowth'])[] = Object.keys(agriculturalGrowth) as (keyof IScreeningFarming['agriculturalGrowth'])[];

      if (agriculturalGrowthKeys.some(key => agriculturalGrowth[key].some((item) => !checkVal(item.value) && item.value !== ''))) {
        for (const key of agriculturalGrowthKeys) {
          if (agriculturalGrowth[key].some((item) => !checkVal(item.value))) {
            setErrors((prev: any) => ({ ...prev, [`agriculturalGrowth-${key}`]: true }));
            toast.error(t(`errors.streetName`, language));
            return false;
          }
        }
      }

      const selfSufficiency = agricultureData.selfSufficiency;
      const selfSufficiencyKeys: (keyof IScreeningFarming['selfSufficiency'])[] = Object.keys(selfSufficiency) as (keyof IScreeningFarming['selfSufficiency'])[];

      if (selfSufficiencyKeys.some(key => checkVal(selfSufficiency[key]) && selfSufficiency[key] !== '')) {
        for (const key of selfSufficiencyKeys) {
          if (!checkVal(selfSufficiency[key])) {
            setErrors((prev: any) => ({ ...prev, [`selfSufficiency-${key}`]: true }));
            toast.error(t(`errors.streetName`, language));
            return false;
          }
        }
      }

      const milk = agricultureData.milk;
      const milkKeys: (keyof IScreeningFarming['milk'])[] = Object.keys(milk) as (keyof IScreeningFarming['milk'])[];

      if (milkKeys.some(key => checkVal(milk[key]) && milk[key] !== '')) {
        for (const key of milkKeys) {
          if (!checkVal(milk[key])) {
            setErrors((prev: any) => ({ ...prev, [`milk-${key}`]: true }));
            toast.error(t(`errors.streetName`, language));
            return false;
          }
        }
      }

      const cropArea = agricultureData.cropArea;
      const cropAreaKeys: (keyof IScreeningFarming['cropArea'])[] = Object.keys(cropArea) as (keyof IScreeningFarming['cropArea'])[];

      if (cropAreaKeys.some(key => Object.keys(cropArea[key]).some(item => checkVal(cropArea[key][item as keyof IFarmingAreaItem]) && cropArea[key][item as keyof IFarmingAreaItem] !== ''))) {
        for (const key of cropAreaKeys) {
          if (Object.keys(cropArea[key]).some(item => !checkVal(cropArea[key][item as keyof IFarmingAreaItem]))) {
            setErrors((prev: any) => ({ ...prev, [`cropArea-${key}`]: true }));
            toast.error(t(`errors.streetName`, language));
            return false;
          }
        }
      }

      const harvest = agricultureData.harvest;
      const harvestKeys: (keyof IScreeningFarming['harvest'])[] = Object.keys(harvest) as (keyof IScreeningFarming['harvest'])[];

      if (harvestKeys.some(key => Object.keys(harvest[key]).some(item => checkVal(harvest[key][item as keyof IFarmingAreaItem]) && harvest[key][item as keyof IFarmingAreaItem] !== ''))) {
        for (const key of harvestKeys) {
          if (Object.keys(harvest[key]).some(item => !checkVal(harvest[key][item as keyof IFarmingAreaItem]))) {
            setErrors((prev: any) => ({ ...prev, [`harvest-${key}`]: true }));
            toast.error(t(`errors.streetName`, language));
            return false;
          }
        }
      }

      const milkProduction = agricultureData.milkProduction;
      const milkProductionKeys: (keyof IScreeningFarming['milkProduction'])[] = Object.keys(milkProduction) as (keyof IScreeningFarming['milkProduction'])[];

      if (milkProductionKeys.some(key => (checkVal(milkProduction[key].yearly && milkProduction[key].yearly !== '') || checkVal(milkProduction[key].average && milkProduction[key].average !== '')))) {
        for (const key of milkProductionKeys) {
          if (!checkVal(milkProduction[key].yearly) || !checkVal(milkProduction[key].average)) {
            setErrors((prev: any) => ({ ...prev, [`milkProduction-${key}`]: true }));
            toast.error(t(`errors.streetName`, language));
            return false;
          }
        }
      }

      return res;
    }

    const handleSubmitForm = (values: any) => {
      setErrors({});

      if (kato && validateForm(agricultureData)) {
        updateScreening(+kato, 'agriculture', { ...agricultureData }, selectedDataYear)
          .then(() => {
            updateForm();
            toast.success(t('toast.save_success'))
          })
      }
    }

    const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setRegion(+e.target.value);
      kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/screening/farming`)
    }

    const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setSnp(+e.target.value)
      navigate(`/admin/${e.target.value}/screening/farming`)
    }

    const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
      return (
        kato && +kato !== OBLAST_KATO && <div className="grid-item">
          <FormGroup>
            <label htmlFor="region">{t(`form.region.name`, lang)}</label>
            <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
              {tree.map((item) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
            </Field>
          </FormGroup>

          {!isKatoRegion && <FormGroup>
            <label htmlFor="snp">{t(`form.district.name`, lang)}</label>
            <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
              {tree.find(item => +item.kato === +region)?.children.map((item: any) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
            </Field>
          </FormGroup>}
        </div>
      )
    }

    useEffect(() => {
      if (data) {
        setAgricultureData(data);
      }
    }, [data]);


    const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
      return <div className="grid-item">
        {
          agricultureData && <>
            {(['animalHusbandry', 'poultry', 'meat', 'yearlyMeat'] as (keyof IScreeningFarming)[]).map((agricultureKey) => (
              agricultureData[agricultureKey] && <FormBlock type='white' key={agricultureKey}>
                <div className="title bold">{t(`screening-page.agriculture.${agricultureKey}.title`, lang)}</div>

                {keys[agricultureKey as keyof typeof keys].map((key) => (
                  <>
                    <div className="title bold">{t(`screening-page.agriculture.${agricultureKey}.${key}`, lang)}</div>
                    {
                      ['kh', 'lph', 'total'].map((item) => (
                        <FormGroup key={`${agricultureKey}-${key}-${item}`}>
                          <label
                            htmlFor={`agricultureData.${agricultureKey}.${key}.${item}`}
                            className="required"
                          >{t(`screening-page.agriculture.${agricultureKey}.${item}`, lang).replace('&type', t(`screening-page.agriculture.${agricultureKey}.of-${key}`, lang))}
                          </label>
                          <Field
                            name={`agricultureData.${agricultureKey}.${key}.${item}`}
                            className={`${errors[`${agricultureKey}-${key}`] && item !== 'total' ? 'error' : ''}`}
                            type='number'
                            as="input"
                            min={0}
                            step={'0.0001'}
                            onChange={(e: any) => {
                              const val = e.target.value || '';
                              setAgricultureData({
                                ...agricultureData,
                                [agricultureKey]: {
                                  ...agricultureData[agricultureKey],
                                  [key]: {
                                    ...(agricultureData[agricultureKey] as any)[key as keyof IFarmingItemsList],
                                    [item]: val
                                  }
                                }
                              });

                              setFieldValue(`agricultureData.${agricultureKey}.${key}.${item}`, +val);
                            }}
                            disabled={item === 'total'}
                            value={item === 'total'
                              ? getKHLPHTotal((agricultureData[agricultureKey] as any)[key as keyof IFarmingItemsList])
                              : (agricultureData[agricultureKey] as any)[key as keyof IFarmingItemsList][item as keyof IFarmingItem] ?? ''}
                          />
                        </FormGroup>
                      ))
                    }
                  </>
                ))}
              </FormBlock>
            ))}

            {
              agricultureData.agriculturalGrowth && <FormBlock type='white'>
                <div className="title bold">{t('screening-page.agriculture.agriculturalGrowth.title', lang)}</div>
                {keysOfAgriculturalGrowth.map((key) => (
                  <>
                    <div className="title bold">{t(`screening-page.agriculture.agriculturalGrowth.${key}`, lang)}</div>
                    {
                      agricultureData.agriculturalGrowth[key as keyof IScreeningFarming['agriculturalGrowth']].map((item, index) => (
                        <FormGroup key={`${key}-${item}-${index}`}>
                          <label
                            htmlFor={`agricultureData.agriculturalGrowth.${key}.${index}.value`}
                            className="required"
                          >{item.year}
                          </label>
                          <Field
                            name={`agricultureData.agriculturalGrowth.${key}.${index}.value`}
                            className={`${errors[`agriculturalGrowth-${key}`] ? 'error' : ''}`}
                            type='text'
                            as="input"
                            min={0}
                            step={'0.0001'}
                            onChange={(e: any) => {
                              const val = e.target.value || '';
                              setAgricultureData({
                                ...agricultureData,
                                agriculturalGrowth: {
                                  ...agricultureData.agriculturalGrowth,
                                  [key]: agricultureData.agriculturalGrowth[key as keyof IScreeningFarming['agriculturalGrowth']].map((item, i) => i === index ? { ...item, value: val } : item)
                                }
                              });

                              setFieldValue(`agricultureData.agriculturalGrowth.${key}.${index}`, +val);
                            }}
                            value={item.value ?? '0'}
                          />
                        </FormGroup>
                      ))
                    }
                  </>
                ))}
              </FormBlock>
            }

            {
              agricultureData.selfSufficiency && <FormBlock type='white'>
                <div className="title bold">{t('screening-page.agriculture.selfSufficiency.title', lang)}</div>
                {keysOfSelfSufficiency.map((key) => (
                  <FormGroup key={key}>
                    <label
                      htmlFor={`agricultureData.selfSufficiency.${key}`}
                      className="required"
                    >{t(`screening-page.agriculture.selfSufficiency.${key}`, lang)}
                    </label>
                    <Field
                      name={`agricultureData.selfSufficiency.${key}`}
                      className={`${errors[`selfSufficiency-${key}`] ? 'error' : ''}`}
                      type='text'
                      as="input"
                      min={0}
                      step={'0.0001'}
                      onChange={(e: any) => {
                        const val = e.target.value || '';
                        setAgricultureData({
                          ...agricultureData,
                          selfSufficiency: {
                            ...agricultureData.selfSufficiency,
                            [key]: val
                          }
                        });

                        setFieldValue(`agricultureData.selfSufficiency.${key}`, +val);
                      }}
                      value={agricultureData.selfSufficiency[key as keyof IScreeningFarming['selfSufficiency']] ?? ''}
                    />
                  </FormGroup>
                ))}
              </FormBlock>
            }

            {
              agricultureData.milk && <FormBlock type='white'>
                <div className="title bold">{t('screening-page.agriculture.milk.title', lang)}</div>
                {
                  (['dairy', 'milkProduction'] as (keyof IScreeningFarming['milk'])[]).map((key) => (
                    <FormGroup key={key}>
                      <label
                        htmlFor={`agricultureData.milk.${key}`}
                        className="required"
                      >{t(`screening-page.agriculture.milk.${key}`, lang)}
                      </label>
                      <Field
                        name={`agricultureData.milk.${key}`}
                        className={`${errors[`milk-${key}`] ? 'error' : ''}`}
                        type='text'
                        as="input"
                        min={0}
                        step={'0.0001'}
                        onChange={(e: any) => {
                          const val = e.target.value || '';
                          setAgricultureData({
                            ...agricultureData,
                            milk: {
                              ...agricultureData.milk,
                              [key]: val
                            }
                          });

                          setFieldValue(`agricultureData.milk`, +val);
                        }}
                        value={agricultureData.milk[key] ?? ''}
                      />
                    </FormGroup>
                  ))
                }
              </FormBlock>
            }

            {(['cropArea', 'harvest'] as (keyof IScreeningFarming)[]).map((agricultureKey) =>
              agricultureData[agricultureKey] && <FormBlock type='white'>
                <div className="title bold">{t(`screening-page.agriculture.${agricultureKey}.title`, lang)}</div>

                {keysOfCropArea.map((key: keyof IScreeningFarming['cropArea' | 'harvest']) => (
                  <>
                    <div className="title bold">{t(`screening-page.agriculture.${agricultureKey}.${key}`, lang)}</div>
                    {
                      keysOfCropAreaItem.map((item: keyof IFarmingAreaItem) => (
                        <FormGroup key={`${key}-${item}`}>
                          <label
                            htmlFor={`agricultureData.${agricultureKey}.${key}.${item}`}
                            className="required"
                          >{t(`screening-page.agriculture.${agricultureKey}.${item}`, lang).replace('&type', t(`screening-page.agriculture.${agricultureKey}.${key}`, lang))}
                          </label>
                          <Field
                            name={`agricultureData.${agricultureKey}.${key}.${item}`}
                            className={`${errors[`${agricultureKey}-${key}`] ? 'error' : ''}`}
                            type='number'
                            as="input"
                            min={0}
                            step={'0.0001'}
                            onChange={(e: any) => {
                              const val = e.target.value || '';
                              setAgricultureData({
                                ...agricultureData,
                                [agricultureKey]: {
                                  ...agricultureData[agricultureKey],
                                  [key]: {
                                    ...(agricultureData[agricultureKey] as any)[key as any],
                                    [item]: val
                                  }
                                }
                              });

                              setFieldValue(`agricultureData.${agricultureKey}.${key}.${item}`, +val);
                            }}
                            value={agricultureData[agricultureKey as 'cropArea' | 'harvest'][key][item] ?? ''}
                          />
                        </FormGroup>
                      ))
                    }

                    <FormGroup >
                      <label
                        htmlFor={`agricultureData.${agricultureKey}.total`}
                        className="required"
                      >{t(`screening-page.agriculture.${agricultureKey}.total`, lang).replace('&type', t(`screening-page.agriculture.${agricultureKey}.${key}`, lang))}
                      </label>
                      <Field
                        name={`agricultureData.${agricultureKey}.total`}
                        className={`${errors[`${agricultureKey}-${key}`] ? 'error' : ''}`}
                        type='number'
                        as="input"
                        min={0}
                        step={'0.0001'}
                        disabled
                        value={
                          getCropTotal(agricultureData[agricultureKey as 'cropArea' | 'harvest'][key]) ?? ''}
                      />
                    </FormGroup>
                  </>
                ))}

              </FormBlock>
            )}

            {
              agricultureData.milkProduction && <FormBlock type='white'>
                <div className="title bold">{t('screening-page.agriculture.milkProduction.title')}</div>
                {Object.keys(agricultureData.milkProduction).map((key) => (
                  <>
                    <div className="title bold">{t(`screening-page.agriculture.milkProduction.${key}`, lang)}</div>

                    {
                      ['yearly', 'average'].map((item) => (
                        <FormGroup key={`milkProduction-${key}-${item}`}>
                          <label
                            htmlFor={`agricultureData.milkProduction.${key}.${item}`}
                            className="required"
                          >{t(`screening-page.agriculture.milkProduction.${item}`, lang).replace('&type', t(`screening-page.agriculture.milkProduction.of-${key}`, lang))}
                          </label>
                          <Field
                            name={`agricultureData.milkProduction.${key}.${item}`}
                            className={`${errors[`milkProduction-${key}`] ? 'error' : ''}`}
                            type='number'
                            as="input"
                            min={0}
                            step={'0.0001'}
                            onChange={(e: any) => {
                              const val = e.target.value || '';
                              setAgricultureData({
                                ...agricultureData,
                                milkProduction: {
                                  ...agricultureData.milkProduction,
                                  [key]: {
                                    ...(agricultureData.milkProduction as any)[key as keyof IFarmingItemsList],
                                    [item]: val
                                  }
                                }
                              });

                              setFieldValue(`agricultureData.milkProduction.${key}.${item}`, +val);
                            }}
                            value={(agricultureData.milkProduction as any)[key as keyof IFarmingItemsList][item as keyof IFarmingItem] ?? ''}
                          />
                        </FormGroup>
                      ))
                    }
                  </>

                ))}
              </FormBlock>
            }
          </>
        }
      </div>
    }

    useEffect(() => {
      kato && updateKatoAccessList(katoAccessList, kato, navigate, setTree, setRegion, setSnp);
    }, [katoAccessList, kato]);

    useEffect(() => {
      if (kato) {
        setIsKatoRegion(isRegion(+kato))
      }
    }, [kato])

    useEffect(() => {
      const item = localStorage.getItem('user');
      if (item) {
        const snpInfo = JSON.parse(item);
        if (snpInfo && snpInfo.kato_access) {
          setKatoAccessList(snpInfo.kato_access)
        }
      }
    }, [])

    return (
      <div style={{ position: 'relative' }}>
        {
          <>
            <Wrapper ref={wrapperRef}>
              <Formik
                initialValues={{}}
                onSubmit={(values) => {
                  handleSubmitForm(values);;
                }}
                innerRef={formikRef}
              >
                {({ setFieldValue }) => (
                  <Form>
                    {renderSelects(getLang())}
                    {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                    {renderFields(getLang(), setFieldValue)}
                    {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                    <Footer>
                      <div className="buttons">
                        <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                      </div>
                      <div className="buttons">
                        <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                      </div>
                    </Footer>
                  </Form>
                )}
              </Formik>
            </Wrapper>
          </>
        }
        <ToastContainer />
      </div>
    )
  }

export default AgriculturePage

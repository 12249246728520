import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ThemeContext from '../../contexts/theme.context';
import { BasicStatsItem } from './index.styles';
import { Text } from '../text.component';
import { ExpandCard } from '../../assets/icons';
import { isRegion } from '../../utils/helpers.utils';
import { ITaxStat } from '../../interfaces/snp.interface';
import { cityObject, OBLAST_KATO } from '../../constants/snp.constant';

interface IProps {
  handleFullScreen: (obj: any) => void,
  data: ITaxStat[],
  kato: number
}

export const tabs = ['REGIONAL_BUDGET', 'PROPER_REGIONAL_BUDGET']

const TaxComponent: FC<IProps> = ({ handleFullScreen, data, kato }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext)
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredTabs, setFilteredTabs] = useState(tabs);

  const findData = (tab: string): ITaxStat => {
    return data.find((item: any) => item.type === tab) || {} as ITaxStat
  }

  const getType = (type: string) => {
    if (kato) {
      if (type === 'REGIONAL_BUDGET') {
        if (isRegion(+kato) && +kato !== OBLAST_KATO) {
          return 'DISTRICT_BUDGET';
        } else if (!isRegion(+kato)) {
          if (+kato === +cityObject.kato) {
            return 'CITY_BUDGET';
          } else {
            return 'SO_BUDGET';
          }
        } else {
          return 'REGIONAL_BUDGET';
        }
      } else if (type === 'PROPER_REGIONAL_BUDGET') {
        if (isRegion(+kato) && +kato !== OBLAST_KATO) {
          return 'PROPER_DISTRICT_BUDGET';
        } else {
          return 'PROPER_REGIONAL_BUDGET';
        }
      }
    }
  }

  useEffect(() => {
    if (kato) {
      if (!isRegion(+kato)) {
        setFilteredTabs(['REGIONAL_BUDGET'])
      } else {
        setFilteredTabs(tabs)
      }
    }
  }, [kato])

  return (
    <BasicStatsItem theme={theme} id="tax">
      <div className="title">
        <div className="left">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`district-income.oblast`)}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{kato && isRegion(+kato) ? 'млн. ' : 'тыс. '}тенге</Text>
        </div>
        <div className="right">
          <span className='unit-container' >
            <ExpandCard onClick={() => handleFullScreen && handleFullScreen({ isOpen: true, type: 'DistrictIncome' })} />
          </span>
        </div>
      </div>

      <div className="body">
        <div className="radios">
          {filteredTabs.map((tab) => <div className="radio">
            <input type="radio" value={`${tab}-tax`} name="type-tax" id={`${tab}-tax`} checked={selectedTab === tab} onChange={() => setSelectedTab(tab)} />
            <label htmlFor={tab} onClick={() => setSelectedTab(tab)}>
              <Text
                fontSize='0.75rem'
                fontWeight={selectedTab === tab ? 700 : 400}
                color={`${selectedTab === tab ? '#118F84' : 'var(--gray)'}`}
                className={`${selectedTab === tab && 'selected'}`}
              >
                {t('taxStats.' + getType(tab))}
              </Text>

            </label>
          </div>)}
        </div>
        <div className="tax-grid">
          {/* ROW 1 */}
          <Text className='header' fontWeight={400} color="#606367" fontSize='0.75rem'>{t('taxStats.income')}</Text>
          <div className="merged">
            <Text className='header' fontWeight={400} color="#606367" fontSize='0.75rem'>{t('taxStats.table-plan')} </Text>
            <Text className='header' fontWeight={400} color="#606367" fontSize='0.625rem'> {t('taxStats.table-period')}</Text>
          </div>

          <Text className='header' fontWeight={400} color="#606367" fontSize='0.75rem'>{t('taxStats.table-fact')}</Text>
          <Text className='header' fontWeight={400} color="#606367" fontSize='0.75rem'>{t('taxStats.table-process')}</Text>
          <div className="merged">
            <Text className='header' fontWeight={400} color="#606367" fontSize='0.75rem'>{t('taxStats.table-plan')} </Text>
            <Text className='header' fontWeight={400} color="#606367" fontSize='0.625rem'> {t('taxStats.table-annual')}</Text>
          </div>
          <Text className='header' fontWeight={400} color="#606367" fontSize='0.75rem'>{t('taxStats.table-process')}</Text>

          {/* ROW 2 */}
          <Text fontWeight={700} color="var(--text-color)" fontSize='0.75rem' style={{ minWidth: "100%", justifyContent: 'flex-start' }}>{t('taxStats.total')} </Text>
          {(['plan', 'fact', 'planPercent', 'annual', 'annualPercent'] as (keyof ITaxStat)[]).map((item: any) =>
            <Text fontWeight={700} className='tax-grid__value' color="var(--primary-color)" fontSize='0.75rem'>{findData(selectedTab)?.[item as keyof ITaxStat]?.toLocaleString().replace('.', ',') || 0}{item.includes('Percent') && '%'}</Text>
          )}


          {/* ROW 3 */}
          <Text fontWeight={400} color="var(--text-color)" fontSize='0.75rem' style={{ justifyContent: 'flex-start' }}>{t('taxStats.tax')}</Text>
          {(['taxRevenuesPlan', 'taxRevenuesFact', 'taxRevenuesPlanPercent', 'taxRevenuesAnnual', 'taxRevenuesAnnualPercent'] as (keyof ITaxStat)[]).map((item: any) =>
            <Text fontWeight={400} className='tax-grid__value' color="var(--primary-color)" fontSize='0.75rem'>{findData(selectedTab)?.[item as keyof ITaxStat]?.toLocaleString().replace('.', ',') || 0}{item.includes('Percent') && '%'}</Text>
          )}


          {/* ROW 4 */}
          <Text fontWeight={400} color="var(--text-color)" fontSize='0.75rem' style={{ justifyContent: 'flex-start' }}>{t('taxStats.nonTax')}</Text>
          {(['nonTaxRevenuesPlan', 'nonTaxRevenuesFact', 'nonTaxRevenuesPlanPercent', 'nonTaxRevenuesAnnual', 'nonTaxRevenuesAnnualPercent'] as (keyof ITaxStat)[]).map((item: any) =>
            <Text fontWeight={400} className='tax-grid__value' color="var(--primary-color)" fontSize='0.75rem'>{findData(selectedTab)?.[item as keyof ITaxStat]?.toLocaleString().replace('.', ',') || 0}{item.includes('Percent') && '%'}</Text>
          )}


          {/* ROW 5 */}
          <Text fontWeight={400} color="var(--text-color)" fontSize='0.75rem' style={{ justifyContent: 'flex-start' }}>{t('taxStats.fixedAssets')}</Text>
          {(['fixedAssetsSalePlan', 'fixedAssetsSaleFact', 'fixedAssetsSalePlanPercent', 'fixedAssetsSaleAnnual', 'fixedAssetsSaleAnnualPercent'] as (keyof ITaxStat)[]).map((item: any) =>
            <Text fontWeight={400} className='tax-grid__value' color="var(--primary-color)" fontSize='0.75rem'>{findData(selectedTab)?.[item as keyof ITaxStat]?.toLocaleString().replace('.', ',') || 0}{item.includes('Percent') && '%'}</Text>
          )}

        </div>
      </div>
    </BasicStatsItem>
  )
}

export default TaxComponent
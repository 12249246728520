import React, { FC, useEffect, useState } from 'react'
import { ScreeningContainer } from './index.styles';
import { useTranslation } from 'react-i18next';
import HumanResourcesTab from '../screening-tabs/human-resources-tab.component';
import LandFundTab from '../screening-tabs/land-fund-tab.component';
import FarmingTab from '../screening-tabs/farming-tab.component';
import MCBTab from '../screening-tabs/mcb-tab.component';
import SwotTab from '../screening-tabs/swot-tab.component';
import { useParams } from 'react-router-dom';
import { getScreening } from '../../../requests/screening.request';
import { IHumanResources, ILandFund, IMCB, ISWOT } from '../../../interfaces/snp.interface';
import { useSharedContext } from '../../../contexts/shared.context';


export const tabs = ['human-resources', 'land-fund', 'farming', 'MCB', 'SWOT'];

const Screening: FC<{ isFull: boolean }> = ({ isFull }) => {
  const { t } = useTranslation();
  const { kato } = useParams();
  const { selectedDataYear } = useSharedContext();

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [humanResources, setHumanResources] = useState<IHumanResources | null>(null);
  const [landFund, setLandFund] = useState<ILandFund | null>(null);
  const [mcb, setMCB] = useState<IMCB | null>(null);
  const [swot, setSWOT] = useState<ISWOT | null>(null);
  const [agriculture, setAgriculture] = useState<any>(null);

  useEffect(() => {
    if (kato) {
      getScreening(+kato, selectedDataYear).then((res) => {
        if (res) {
          setHumanResources(res.humanResources);
          setLandFund(res.landFund);
          setMCB(res.smbDevelopment);
          setSWOT(res.swotAnalysis);
          setAgriculture(res.agriculture);
        }
      });
    }
  }, [kato, selectedDataYear]);

  return (
    <ScreeningContainer isFull={isFull}>
      <div className="tabs">
        {tabs.map((tab: string) => (
          <div
            key={tab}
            className={selectedTab === tab ? 'tab active' : 'tab'}
            onClick={() => setSelectedTab(tab)}
          >
            {t('screening-page.' + tab)}
          </div>
        ))}
      </div>

      {
        selectedTab === tabs[0] && humanResources && <HumanResourcesTab data={humanResources} />
      }

      {
        selectedTab === tabs[1] && landFund && <LandFundTab data={landFund} />
      }

      {
        selectedTab === tabs[2] && agriculture && <FarmingTab data={agriculture} />
      }

      {
        selectedTab === tabs[3] && mcb && <MCBTab data={mcb} />
      }

      {
        selectedTab === tabs[4] && swot && <SwotTab data={swot} />
      }

      {
        selectedTab === tabs[5] && <div>Result</div>
      }
    </ScreeningContainer>
  )
}

export default Screening
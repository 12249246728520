import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { BasicStatsItem } from './index.styles';
import { Text } from '../text.component';
import { IBudgetInvestItem } from '../../interfaces/snp.interface';
import { isRegion } from '../../utils/helpers.utils';
import { ExpandCard } from '../../assets/icons';
import { useParams } from 'react-router-dom';
import { cityObject, OBLAST_KATO } from '../../constants/snp.constant';

const months: Record<string, string[]> = {
  ru: [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
  ],
  kz: [
    'қаңтарға', 'ақпанға', 'наурызға', 'сәуірге', 'мамырға', 'маусымға',
    'шілдеге', 'тамызға', 'қыркүйекке', 'қазанға', 'қарашаға', 'желтоқсанға'
  ]
}


interface IProps {
  handleFullScreen: (obj: any) => void;
  budgetData: IBudgetInvestItem[];
}

interface IGridItem {
  plan: string;
  fact: string;
  plan2024: string;
  planPercent: string;
  plan2024Percent: string;
}

const keysOrder: (keyof IGridItem)[] = ['plan', 'fact', 'planPercent', 'plan2024', 'plan2024Percent'];
const typeOrder: ('total' | 'tek_budget' | 'budget_razv')[] = ['total', 'tek_budget', 'budget_razv'];

const tabs = ['REGIONAL_BUDGET', 'DEDICATED_REGIONAL_BUDGET'];

export const getRatio = (fact: number, plan: number) => {
  return plan === 0 ? '0%' : `${(fact / plan * 100).toFixed(2)}%`
}

export const divideByMillion = (num: number) => {
  return (+((num / 1000000).toFixed(2))).toLocaleString('ru-RU')
}

const InvestProjects: FC<IProps> = ({ handleFullScreen, budgetData }) => {
  const { t, i18n: { language } } = useTranslation();
  const { kato } = useParams()

  const [filteredTabs, setFilteredTabs] = useState<string[]>(tabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [budget, setBudget] = useState<{ [key in 'budget_razv' | 'total' | 'tek_budget']?: IGridItem }>({});

  const getType = (type: string) => {
    if (kato) {
      if (type === 'REGIONAL_BUDGET') {
        if (isRegion(+kato) && +kato !== OBLAST_KATO) {
          return 'DISTRICT_BUDGET';
        } else if (!isRegion(+kato)) {
          if (+kato === +cityObject.kato) {
            return 'CITY_BUDGET';
          } else {
            return 'SO_BUDGET';
          }
        } else {
          return 'REGIONAL_BUDGET';
        }
      } else if (type === 'DEDICATED_REGIONAL_BUDGET') {
        if (isRegion(+kato) && +kato !== OBLAST_KATO) {
          return 'PROPER_DISTRICT_BUDGET';
        } else if (!isRegion(+kato)) {
          if (+kato === +cityObject.kato) {
            return 'CITY_BUDGET';
          } else {
            return 'SO_BUDGET';
          }
        } else {
          return 'PROPER_REGIONAL_BUDGET';
        }
      }
    }
  }

  useEffect(() => {
    if (budgetData) {
      const tek = budgetData.find((item) => item.devType === 'tek_budget' && item.typeBudget === selectedTab)
      const razv = budgetData.find((item) => item.devType === 'budget_razv' && item.typeBudget === selectedTab)
      if (!tek && !razv) return;

      const total = {
        plan: divideByMillion(+(tek?.plan ?? 0) + +(razv?.plan ?? 0)),
        fact: divideByMillion(+(tek?.fact ?? 0) + +(razv?.fact ?? 0)),
        plan2024: divideByMillion(+(tek?.plan2024 ?? 0) + +(razv?.plan2024 ?? 0)),
        planPercent: getRatio(+(tek?.fact ?? 0) + +(razv?.fact ?? 0), +(tek?.plan ?? 0) + +(razv?.plan ?? 0)),
        plan2024Percent: getRatio(+(tek?.fact ?? 0) + +(razv?.fact ?? 0), +(tek?.plan2024 ?? 0) + +(razv?.plan2024 ?? 0)),
      }

      const tek_budget = {
        plan: divideByMillion(tek?.plan ?? 0),
        fact: divideByMillion(tek?.fact ?? 0),
        plan2024: divideByMillion(tek?.plan2024 ?? 0),
        planPercent: getRatio(tek?.fact ?? 0, tek?.plan ?? 0),
        plan2024Percent: getRatio(tek?.fact ?? 0, tek?.plan2024 ?? 0),
      }

      const budget_razv = {
        plan: divideByMillion(razv?.plan ?? 0),
        fact: divideByMillion(razv?.fact ?? 0),
        plan2024: divideByMillion(razv?.plan2024 ?? 0),
        planPercent: getRatio(razv?.fact ?? 0, razv?.plan ?? 0),
        plan2024Percent: getRatio(razv?.fact ?? 0, razv?.plan2024 ?? 0),
      }

      setBudget({ total, tek_budget, budget_razv })
    }
  }, [budgetData, selectedTab])

  useEffect(() => {
    const availableTabs = budgetData.map((item) => item.typeBudget).sort((a: string, b: string) => b.localeCompare(a));

    setFilteredTabs(Array.from(new Set(availableTabs)));
    setSelectedTab(availableTabs[0]);

  }, [budgetData])

  return (
    budget && <BasicStatsItem id="invest-projects">
      <div className="title">
        <div className="left">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`budgetInvestmentProjectStats.osvoenie`)}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{kato && isRegion(+kato) ? 'млн. ' : t('form.thousand')}тенге</Text>
        </div>

        <div className="right" style={{ display: 'flex', alignItems: 'center' }}>
          <span className='unit-container'>
            <ExpandCard onClick={() => handleFullScreen && handleFullScreen({ isOpen: true, type: 'InvestProjects' })} />
          </span>
        </div>

      </div>
      <div className="body">
        <div className="radios">
          {filteredTabs.map((tab) => <div className="radio">
            <input type="radio" value={tab} name="type-invest" id={`${tab}-invest`} checked={selectedTab === tab} onChange={() => setSelectedTab(tab)} />
            <label htmlFor={tab} onClick={() => setSelectedTab(tab)}>
              <Text
                fontSize='0.75rem'
                fontWeight={selectedTab === tab ? 700 : 400}
                color={`${selectedTab === tab ? '#118F84' : 'var(--gray)'}`}
                className={`${selectedTab === tab && 'selected'}`}
              >
                {t('taxStats.' + getType(tab))}
              </Text>

            </label>
          </div>)}
        </div>
        <div className="tax-grid">
          {/* ROW 1 */}
          <Text className='header' fontWeight={400} color="var(--text-color)" fontSize='0.75rem'></Text>

          <div className="merged">
            <Text className='header' fontSize='0.75rem' color='#606367' fontWeight={400}>{t('taxStats.table-plan')} </Text>
            <Text className='header' fontSize='0.625rem' color='#606367' fontWeight={400}> {t('taxStats.table-date', { month: months[language][new Date().getMonth()] })}   </Text>
          </div>
          <Text className='header' fontSize='0.75rem' color='#606367' fontWeight={400}>{t('taxStats.table-fact')} </Text>
          <Text className='header' fontSize='0.75rem' color='#606367' fontWeight={400}>{t('taxStats.table-process')} </Text>
          <div className="merged">
            <Text className='header' fontSize='0.75rem' color='#606367' fontWeight={400}>{t('taxStats.table-plan')}</Text>
            <Text className='header' fontSize='0.625rem' color='#606367' fontWeight={400}>{t('taxStats.table-year', { year: new Date().getFullYear() })} </Text>
          </div>
          <Text className='header' fontSize='0.75rem' color='#606367' fontWeight={400}>{t('taxStats.table-process')}</Text>

          {/* ROW 2 */}

          {typeOrder.map((type: 'total' | 'tek_budget' | 'budget_razv', index) => (
            <>
              <Text fontSize='0.75rem' color='var(--text-color)' style={{ justifyContent: 'flex-start' }} fontWeight={index === 0 ? 700 : 400} >{t(`budgetInvestmentProjectStats.${type}`)}</Text>
              {keysOrder.map((key) => (
                <Text fontSize='0.75rem' className='tax-grid__value' color='var(--primary-color)' fontWeight={index === 0 ? 700 : 400} >{budget[type]?.[key].toString().replace('.', ',') ?? 0}</Text>
              ))}
            </>
          ))}

        </div>
      </div>
    </BasicStatsItem >
  )
}

export default InvestProjects